import { HpAction, HpTemplateActionTypes } from '../actions/actionTypes';
import { GetFeatureToggleAction, GetFeatureToggleActionPayload } from '../actions/getFeatureToggleStateAction';
import { FeatureToggleState, getInitialState } from './initialState';
import { ReducerProcessor } from './reducerProcessor';

const processor = new ReducerProcessor<FeatureToggleState, HpTemplateActionTypes>('Assumptions');

processor.add<GetFeatureToggleAction>('RETRIEVED_FEATURE_TOGGLE_STATE', (state, action) => {
  const { featureName, isEnabled } = action.payload || {};
  if (featureName && isEnabled !== undefined) {
    const newState = new Map(state);
    newState.set(featureName, isEnabled);
    return newState;
  }

  return state;
});

export function featureToggleReducer(
  state: FeatureToggleState = getInitialState().featureToggles,
  action: HpAction<GetFeatureToggleActionPayload>
): FeatureToggleState {
  return processor.process(state, action);
}
