import { formatPostCode } from '@avantia/client-and-server-utilities';
import { Form } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { shallowEqual } from 'react-redux';

import { sendAuthLinkAction } from 'actions/sendAuthLinkAction';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { TextInputControl } from 'components/common/textInputControl';
import { Button } from 'components/common/button';

import './loginForm.scss';
import type { LoginOptions } from '../loginPage';
import { LoginError } from './loginError';
import { GTM_EVENT_TYPES, sendAnalytics } from 'analytics';

export interface LoginFormProps {
  title: string;
  helperText: JSX.Element;
  tab: LoginOptions;
  path?: string;
}

export const LoginForm = ({ title, helperText, tab, path }: LoginFormProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const {
    loginValidationErrors,
    loginError,
    authLinkFailed,
    customerInsuranceSummaryError,
    authLinkError,
    isTokenError,
    isPolicyDetailsError,
    policyDetailsError
  } = useAppSelector(
    ({
      login: { loginError, loginValidationErrors, authLinkFailed },
      customerData: {
        customerInsuranceSummaryError,
        authLinkError,
        isTokenError,
        isPolicyDetailsError,
        policyDetailsError
      }
    }) => ({
      loginError,
      loginValidationErrors,
      authLinkFailed,
      customerInsuranceSummaryError,
      authLinkError,
      isTokenError,
      isPolicyDetailsError,
      policyDetailsError
    }),
    shallowEqual
  );

  const [form, setForm] = useState({
    emailAddress: '',
    postcode: '',
    tab
  });

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handlePostcodeBlur = (e) => {
    setForm({ ...form, postcode: formatPostCode(e.target.value) });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(sendAuthLinkAction(form));
  };

  useEffect(() => {
    sendAnalytics({
      event: GTM_EVENT_TYPES.PAGE_VIEW,
      detail: {
        virtualpage_title: `Homeprotect • ${title}`,
        virtualpage: path,
        virtualpage_url: document.location.href
      }
    });
  }, []);

  return (
    <Form onSubmit={handleSubmit} className="login-form">
      {authLinkFailed && <LoginError error={loginError} />}
      {customerInsuranceSummaryError && !isTokenError && <LoginError error={authLinkError} />}
      {isPolicyDetailsError && <LoginError error={policyDetailsError} />}
      <h2 className="login-title">{title}</h2>
      {helperText}
      <TextInputControl
        label="Email"
        placeholder="name@email.com"
        value={form.emailAddress}
        handleChange={handleChange}
        name="emailAddress"
        error={loginValidationErrors && loginValidationErrors.emailAddress}
      />
      <TextInputControl
        label="Postcode"
        placeholder="e.g. EC4Y 8EN"
        value={form.postcode}
        handleBlur={handlePostcodeBlur}
        handleKeyDown={(e) => e.key === 'Enter' && handlePostcodeBlur(e)}
        handleChange={handleChange}
        name="postcode"
        error={loginValidationErrors && loginValidationErrors.postcode}
        fullWidth={false}
      />
      <Button text="EMAIL MY ACCESS LINK" type="submit" id="email-access-link" />
    </Form>
  );
};
