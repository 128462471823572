import { useLocation } from 'react-router-dom';
import { Container } from 'react-bootstrap';

import { useAppSelector } from 'store/hooks';
import { useGetScreenSize } from 'components/utils';

import { Banner } from './banner';
import { Navbar } from './navbar';
import { Title } from './title';
import { PATH } from 'interfaces/pathTypes';
import type { Path } from 'interfaces/pathTypes';
import './header.scss';

export const Header = (): JSX.Element => {
  const { pathname } = useLocation();
  const showAlert =
    Object.values(PATH)
      .filter((item) => item !== PATH.DASHBOARD)
      .findIndex((path) => `/${pathname.split('/')[1]}` === path) === -1;
  const isPolicyDetailsLoading = useAppSelector((state) => state.customerData.policyDetailsLoading);
  const isCustomerInsuranceSummaryLoading = useAppSelector(
    (state) => state.customerData.customerInsuranceSummaryLoading
  );
  const { isTablet, isMobile } = useGetScreenSize();

  if (isPolicyDetailsLoading || isCustomerInsuranceSummaryLoading) {
    return (
      <header tabIndex={-1} className="header">
        <Container fluid={isTablet || isMobile}>
          <Navbar />
        </Container>
      </header>
    );
  }

  return (
    <header tabIndex={-1} className="header">
      <Container fluid={isTablet || isMobile}>
        <Navbar />
        {showAlert && (
          <Banner
            title="HOMEPROTECT PRICE PROMISE"
            body="When we offer you a renewal price, it will always be the same as, or better than, the price offered to new customers"
          />
        )}
        <Title pathname={pathname as Path} />
      </Container>
    </header>
  );
};
