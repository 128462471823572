import { HpSvgIconTypes, SvgIcon } from '@avantia/style-guide';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';

import { getConfig } from 'config/configuration';
import { getPolicyStatusText, useGetScreenSize, getDaysBetween } from 'components/utils';
import { EXTERNAL_PATH, PATH } from 'interfaces/pathTypes';
import { POLICY_STATUS, PolicyStatus as PolicyStatusType } from 'interfaces/customerDataTypes';
import { ArrowLink } from 'components/common/arrowLink';
import { formatDate } from 'components/utils';
import { PolicyField } from '../../../../common/policyField';
import { ExternalLink } from 'components/common/externalLink';

import './policyStatus.scss';
import { addDays } from 'date-fns';

const { assetsUrl } = getConfig();

interface PolicyStatusProps {
  policyStatus: PolicyStatusType;
  endDate: string;
  stopRenewal: boolean;
  policyRef: string;
}

interface PolicyStatusDisplay {
  iconUrl: string;
  helperText?: JSX.Element;
  link?: JSX.Element;
}

const getStatusDetails = (policyStatus: PolicyStatusType, endDate: string, policyRef: string): PolicyStatusDisplay => {
  switch (policyStatus) {
    case POLICY_STATUS.ACTIVE:
      return { iconUrl: assetsUrl + 'website/svg/policy-active.svg' };
    case POLICY_STATUS.RENEWED:
      return {
        iconUrl: assetsUrl + 'website/svg/policy-active.svg',
        helperText: <p>Thank you for renewing your home insurance with Homeprotect.</p>
      };
    case POLICY_STATUS.TIME_TO_RENEW:
      return {
        iconUrl: assetsUrl + 'website/svg/policy-time-to-renew.svg',
        helperText: (
          <p>
            This policy is set to auto-renew in {getDaysBetween(endDate)} days. You don&apos;t need to take in action if
            you&apos;re happy with your renewal invite.
          </p>
        ),
        link: (
          <Link to={`${PATH.DOCUMENTS}/policy/${policyRef}`} className="documents-link">
            <ArrowLink linkText="Renewal documents" />
          </Link>
        )
      };
    case POLICY_STATUS.CONTACT_US:
      return {
        iconUrl: assetsUrl + 'website/svg/policy-action-required.svg',
        helperText: (
          <p>
            We need to speak with you before renewing this policy. Please call us on{' '}
            <span className="bold-text">0330 660 1000</span>.
          </p>
        ),
        link: (
          <ExternalLink href={EXTERNAL_PATH.CONTACT_US} id="contact-us">
            <Button id="policy-button">
              <p className="av-text-large policy-button-text">Contact us</p>
            </Button>
          </ExternalLink>
        )
      };
    case POLICY_STATUS.EXPIRING:
      return {
        iconUrl: assetsUrl + 'website/svg/policy-action-required.svg',
        helperText: (
          <p>
            This policy is set to expire in {getDaysBetween(endDate)} days. Update your{' '}
            <Link to={`${PATH.SETTINGS}/policy/${policyRef}`}>auto-renewal preference</Link> to renew this policy is
            you&apos;re happy with your renewal invite.
          </p>
        ),
        link: (
          <Link to={`${PATH.SETTINGS}/policy/${policyRef}`}>
            <Button id="policy-button">
              <p className="av-text-large policy-button-text">Update auto-renewal</p>
            </Button>
          </Link>
        )
      };
    case POLICY_STATUS.PAYMENT_REQUIRED:
      return {
        iconUrl: assetsUrl + 'website/svg/policy-action-required.svg',
        helperText: <p>Your policy renewal has failed.</p>,
        link: (
          <Link to={`${PATH.RENEWAL_PAYMENT}/policy/${policyRef}`}>
            <Button id="policy-button">
              <p className="av-text-large policy-button-text">Make a payment</p>
            </Button>
          </Link>
        )
      };
    default:
      return { iconUrl: assetsUrl + 'website/svg/policy-active.svg' };
  }
};

const getStyles = (policyStatus: PolicyStatusType): string => {
  if (
    policyStatus === POLICY_STATUS.CANCELLED ||
    policyStatus === POLICY_STATUS.CONTACT_US ||
    policyStatus === POLICY_STATUS.EXPIRING ||
    policyStatus === POLICY_STATUS.PAYMENT_REQUIRED
  ) {
    return 'policy-status-text-warning';
  }

  return 'policy-status-text-standard';
};

export const PolicyStatus = ({ policyStatus, endDate, stopRenewal, policyRef }: PolicyStatusProps): JSX.Element => {
  const { isMobile } = useGetScreenSize();
  const autoRenewalPreference = !stopRenewal ? (
    <p>Automatically renew this policy</p>
  ) : (
    <div className="auto-renewal-message">
      <p>Cover will end on {formatDate(endDate)}</p>
      <SvgIcon type={'pen' as HpSvgIconTypes} size="very-tiny" />
      <Link to={`${PATH.SETTINGS}/policy/${policyRef}`}>Edit</Link>
    </div>
  );
  const { iconUrl, link, helperText } = getStatusDetails(policyStatus, endDate, policyRef);

  return (
    <>
      <div className="policy-status">
        <img alt="Policy status icon" src={iconUrl} height={isMobile ? 40 : 64} width={isMobile ? 40 : 64}></img>
        <p className={getStyles(policyStatus)} id="policy-status">
          {getPolicyStatusText(policyStatus)}
        </p>
      </div>
      {helperText && helperText}
      {link && link}
      <PolicyField label="Renewal date" value={<p>{formatDate(addDays(new Date(endDate), 1))}</p>} />
      <PolicyField label="Auto-renewal preference" value={autoRenewalPreference} />
    </>
  );
};
