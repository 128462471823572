import { voidFunction } from '@avantia/client-and-server-utilities';
import { HpError } from '@avantia/logging-and-errors';
import { BaseSyntheticEvent } from 'react';

export const ExternalLink = ({ children, className, content, href, id, handler }: HpExternalLinkProps): JSX.Element => {
  if (!!children && !!content) {
    throw new HpError(`ExternalLink component can have either 'content' or 'children' but not both.`);
  }

  let link: HTMLAnchorElement | null = null;
  let onKeyPressFunc: (event: BaseSyntheticEvent) => void | VoidFunction = voidFunction;
  if (handler) {
    onKeyPressFunc = (e: BaseSyntheticEvent) => {
      window.open(link as any, '_blank');
      handler(e);
    };
  }

  return (
    <a
      id={id}
      ref={(ref: HTMLAnchorElement | null) => (link = ref)}
      className={className}
      href={href}
      target="_blank"
      rel="noreferrer noopener"
      onClick={handler}
      onAuxClick={handler}
      onContextMenu={handler}
      onKeyPress={onKeyPressFunc}
      tabIndex={0}>
      {children || content || href}
    </a>
  );
};

export interface HpExternalLinkProps {
  children?: string | JSX.Element | JSX.Element[];
  className?: string;
  content?: JSX.Element | string;
  handler?: (event: BaseSyntheticEvent) => void | VoidFunction;
  href: string;
  id: string;
}
