import { HpAction, HpTemplateActionTypes } from '../actions/actionTypes';
import { LoginState, getInitialState } from './initialState';
import { ReducerProcessor } from './reducerProcessor';

const processor = new ReducerProcessor<LoginState, HpTemplateActionTypes>('login');

processor.add('LOGIN_VALIDATION_ERROR', (state, action) => {
  return {
    ...state,
    loginValidationErrors: action.payload,
    authLinkFailed: false
  };
});

processor.add('LOGIN_VALIDATION_SUCCESS', (state) => {
  return {
    ...state,
    loginProcessing: true,
    loginValidationErrors: undefined
  };
});

processor.add('EMAIL_AUTH_LINK_ERROR', (state, action) => {
  return {
    ...state,
    loginProcessing: false,
    authLinkFailed: true,
    loginError: action.payload,
    loginValidationErrors: undefined
  };
});

processor.add('EMAIL_AUTH_LINK_SUCCESS', (state) => {
  return {
    ...state,
    loginProcessing: false,
    authLinkSuccess: true,
    loginErrors: undefined
  };
});

export function loginReducer(
  state: LoginState = getInitialState().login,
  action: HpAction<string | { [key: string]: string }>
): LoginState {
  return processor.process(state, action);
}
