import { LOGIN_PATH } from 'interfaces/pathTypes';

export const getSignOutUrl = (pathname: string): string => {
  const paths = pathname.split('/');

  if (paths.findIndex((path) => `/${path}` === LOGIN_PATH.CLAIMS) > -1) {
    return `/login${LOGIN_PATH.CLAIMS}`;
  }

  if (paths.findIndex((path) => `/${path}` === LOGIN_PATH.QUOTES) > -1) {
    return `/login${LOGIN_PATH.QUOTES}`;
  }

  return `/login${LOGIN_PATH.DEFAULT}`;
};
