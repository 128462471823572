export const DASHBOARD_NAV_IMAGE = {
  CLAIMS: 'customerportal/claim.png',
  DOCUMENTS: 'customerportal/laptop-man.png',
  FAQ: 'customerportal/camera-man.png'
} as const;

export type DashboardNavImage = (typeof DASHBOARD_NAV_IMAGE)[keyof typeof DASHBOARD_NAV_IMAGE];

export const PAGE_NAV_IMAGE = {
  DASHBOARD: 'customerportal/dashboard.png',
  CLAIMS: 'customerportal/claim.png',
  DOCUMENTS: 'customerportal/laptop-man.png',
  CONTACT: 'customerportal/laptop-woman.png',
  FAQ: 'customerportal/camera-man-zoomed.png'
} as const;

export type PageNavImage = (typeof PAGE_NAV_IMAGE)[keyof typeof PAGE_NAV_IMAGE];
