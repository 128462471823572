import { HpChildComponentProps } from 'components/common/componentWithModal/componentWithModal';
import { PageNavigation } from 'components/navigation/pageNavigation';
import { PATH } from 'interfaces/pathTypes';

import { AccountSettings } from './accountSettings';
import { AutoRenewalSettings } from './autoRenewalSettings';

import './settings.scss';
import { RENEWAL_DISPLAY_WARNING } from 'interfaces/customerDataTypes';

export const Settings = ({ selectedPolicy }: HpChildComponentProps): JSX.Element => {
  return (
    <>
      <div className="settings-container">
        {selectedPolicy && (
          <>
            <AccountSettings selectedPolicy={selectedPolicy} />
            {selectedPolicy.renewalDisplayWarning !== RENEWAL_DISPLAY_WARNING.EXPIRED_POLICY && (
              <AutoRenewalSettings selectedPolicy={selectedPolicy} />
            )}
          </>
        )}
      </div>
      <PageNavigation page={PATH.SETTINGS} />
    </>
  );
};
