import { differenceInDays, isValid } from 'date-fns';

export const getDaysBetween = (date: string): string => {
  const dateObj = new Date(date);
  if (isValid(dateObj)) {
    const differenceNumber = differenceInDays(dateObj, new Date());
    return String(differenceNumber);
  }

  return 'N/A';
};
