import { SvgIcon } from '@avantia/style-guide';
import type { HpSvgIconTypes } from '@avantia/style-guide';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { PolicyStatus } from './policyStatus';
import { PolicyField } from '../../../common/policyField';
import { formatAddress } from 'components/utils';
import type { PolicyDetail } from 'interfaces/customerDataTypes';

import './policy.scss';

interface PolicyProps {
  selectedPolicy: PolicyDetail;
  isMultiplePolicies: boolean;
  onClickSelect: (arg: boolean) => void;
}
export const Policy = ({ selectedPolicy, isMultiplePolicies, onClickSelect }: PolicyProps): JSX.Element => {
  return (
    <Row className="policy-row">
      <Col className="policy-column" xs={12} sm={6} lg={6}>
        <h2>HOME INSURANCE</h2>
        <p>View your documents, make policy changes, or check your cover and submit a claim.</p>
        <PolicyField
          label="Address insured"
          value={<p>{formatAddress(selectedPolicy.riskAddress, selectedPolicy.riskPostcode)}</p>}
        />
        <PolicyField label="Policy reference" value={<p id="policy-reference-text">{selectedPolicy.policyRef}</p>} />
        {isMultiplePolicies && (
          <Link to="/dashboard" onClick={() => onClickSelect(true)}>
            <SvgIcon type={'document' as HpSvgIconTypes} size="very-tiny" className="policy-icon" />
            <p className="policy-text" id="open-policy-modal">
              View another policy
            </p>
          </Link>
        )}
      </Col>
      <Col className="policy-column" xs={12} sm={5} lg={5}>
        <PolicyStatus
          policyStatus={selectedPolicy.policyStatus}
          endDate={selectedPolicy.endDate}
          stopRenewal={selectedPolicy.stopRenewal}
          policyRef={selectedPolicy.policyRef}
        />
      </Col>
    </Row>
  );
};
