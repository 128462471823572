import type { HpSvgIconTypes } from '@avantia/style-guide';
import { Container } from 'react-bootstrap';
import { SvgIcon } from '@avantia/style-guide';
import { getConfig } from 'config/configuration';

import './loading.scss';

const { assetsUrl } = getConfig();

interface LoadingProps {
  heading: string;
  text: string;
  icon?: HpSvgIconTypes;
}

export const Loading = ({ heading, text, icon }: LoadingProps): JSX.Element => {
  return (
    <Container>
      <div className="loading-container">
        {icon && (
          <div className="circle">
            <SvgIcon type={icon} className="loading-icon" />
          </div>
        )}
        <h2>{heading}</h2>
        <img src={assetsUrl + 'website/page-loading.gif'} alt="Loading dots"></img>
        <p>{text}</p>
      </div>
    </Container>
  );
};
