import type { HpChildComponentProps } from 'components/common/componentWithModal/componentWithModal';
import { useState } from 'react';
import './renewalPayment.scss';
import { PaymentInfoPage } from './paymentInfoPage';
import { PaymentPortalPage } from './paymentPortalPage';
import { PageNavigation } from 'components/navigation/pageNavigation';
import { PATH } from 'interfaces/pathTypes';

export const RenewalPayment = ({ selectedPolicy }: HpChildComponentProps): JSX.Element => {
  const [clickedContinue, setClickedContinue] = useState(false);
  const [acceptedTerms, setAcceptedTerms] = useState(false);

  return (
    <>
      <div className="payment-container">
        {clickedContinue ? (
          selectedPolicy.renewalPaymentAmount && (
            <PaymentPortalPage renewalPaymentAmount={selectedPolicy.renewalPaymentAmount} />
          )
        ) : (
          <PaymentInfoPage
            selectedPolicy={selectedPolicy}
            setClickContinue={setClickedContinue}
            acceptedTerms={acceptedTerms}
            setAcceptedTerms={setAcceptedTerms}
          />
        )}
      </div>
      <PageNavigation page={PATH.RENEWAL_PAYMENT} />
    </>
  );
};
