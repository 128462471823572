import { configureStore } from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';

import { getInitialState } from 'reducers/initialState';
import rootReducer from '../reducers/index';
import { getConfig } from '../config/configuration';

const { developerModeEnabled } = getConfig();

const store = configureStore({
  reducer: rootReducer,
  preloadedState: getInitialState(),
  middleware: (getDefaultMiddleware) =>
    developerModeEnabled
      ? getDefaultMiddleware({ serializableCheck: false }).concat(createLogger())
      : getDefaultMiddleware({ serializableCheck: false })
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export default store;
