import {
  validateFields,
  toFields,
  createField,
  HpRequiredValidator,
  HpEmailAddressValidator,
  HpPostcodeValidator
} from '@avantia/form-validation';
import type { HpPostcodeValidatorProps, HpEmailAddressValidatorProps } from '@avantia/form-validation';
import type { HpValidationResult } from '@avantia/form-validation';
import type { AuthLinkEmailPayload } from 'actions/sendAuthLinkAction';

export const getLoginValidation = (payload: AuthLinkEmailPayload): HpValidationResult => {
  const emailAddressValidation = createField({
    key: 'emailAddress',
    value: payload.emailAddress,
    validators: [
      new HpRequiredValidator({ message: 'Please enter your email address' }),
      new HpEmailAddressValidator({ message: 'Please enter a valid email address' } as HpEmailAddressValidatorProps)
    ]
  });

  const postcodeValidation = createField({
    key: 'postcode',
    value: payload.postcode,
    validators: [
      new HpRequiredValidator({ message: 'Please enter your postcode' }),
      new HpPostcodeValidator({ message: 'Please enter a valid postcode' } as HpPostcodeValidatorProps)
    ]
  });

  return validateFields({
    fields: toFields({ emailAddress: emailAddressValidation, postcode: postcodeValidation }),
    fieldKeysToValidate: undefined
  });
};
