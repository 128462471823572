import type { HpSvgIconTypes } from '@avantia/style-guide';

import { HpAlert } from 'components/common/alert';
import { RENEWAL_DISPLAY_WARNING } from 'interfaces/customerDataTypes';
import type { RenewalDisplayWarning } from 'interfaces/customerDataTypes';
import { ExternalLink } from 'components/common/externalLink';
import { EXTERNAL_PATH } from 'interfaces/pathTypes';

import './accountMessage.scss';

interface AccountMessageProps {
  message: RenewalDisplayWarning;
}

export const RENEWAL_MESSAGE = {
  [RENEWAL_DISPLAY_WARNING.CUSTOMER_CALL_IN]: 'Please give us a call',
  [RENEWAL_DISPLAY_WARNING.CUSTOMER_OPTED_OUT]: 'Action required',
  [RENEWAL_DISPLAY_WARNING.EXPIRED_POLICY]: 'Policy expired',
  [RENEWAL_DISPLAY_WARNING.RENEWAL_BEING_PROCESSED]: 'Renewal in progress'
};

const getRenewalDisplayWarningElement = (message: RenewalDisplayWarning): JSX.Element => {
  switch (message) {
    case RENEWAL_DISPLAY_WARNING.CUSTOMER_CALL_IN:
      return (
        <p className="account-message-text">
          We need to speak with you before renewing this policy. Please call us on 0330 660 1000.
        </p>
      );
    case RENEWAL_DISPLAY_WARNING.CUSTOMER_OPTED_OUT:
      return (
        <p className="account-message-text">
          This policy is not set to renew. Get in{' '}
          <ExternalLink href={EXTERNAL_PATH.CONTACT_US} id="contact-us" className="link-text">
            contact with us here
          </ExternalLink>{' '}
          to renew this policy.
        </p>
      );
    case RENEWAL_DISPLAY_WARNING.EXPIRED_POLICY:
      return (
        <p className="account-message-text">
          Your policy has now expired. To reinstate your cover,{' '}
          <ExternalLink href={EXTERNAL_PATH.CONTACT_US} id="contact-us" className="link-text">
            contact us
          </ExternalLink>{' '}
          or purchase a new policy.
        </p>
      );
    case RENEWAL_DISPLAY_WARNING.RENEWAL_BEING_PROCESSED:
      return (
        <p className="account-message-text">
          Your renewal is being processed. Get in{' '}
          <ExternalLink href={EXTERNAL_PATH.CONTACT_US} id="contact-us" className="link-text">
            contact with us here
          </ExternalLink>{' '}
          to amend this policy.
        </p>
      );
    default:
      return <p className="account-message-text">{message}</p>;
  }
};

export const AccountMessage = ({ message }: AccountMessageProps): JSX.Element => {
  return (
    <HpAlert
      iconType={'info' as HpSvgIconTypes}
      alertLevel="error"
      message={getRenewalDisplayWarningElement(message)}
      header={RENEWAL_MESSAGE[message]}
      className="account-message-alert"></HpAlert>
  );
};
