import { Row, Col } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

import { PATH, EXTERNAL_PATH } from 'interfaces/pathTypes';
import { DASHBOARD_NAV_IMAGE } from 'interfaces/navigationTypes';

import type { DashboardNavigationItemProps } from './dashboardNavigationItem/dashboardNavigationItem';
import { DashboardNavigationItem } from './dashboardNavigationItem';
import { getPolicyFromUrl } from 'components/common/multiplePolicyModal/utils/getPolicyFromUrl';

import './dashboardNavigation.scss';

const getPageCards = (policyPath: string): DashboardNavigationItemProps[] => [
  {
    title: 'MY DOCUMENTS',
    body: 'You can view and download your policy documents here.',
    linkText: 'View my documents',
    imageUrl: DASHBOARD_NAV_IMAGE.DOCUMENTS,
    linkUrl: `${PATH.DOCUMENTS}/${policyPath}`,
    cardKey: 'documents'
  },
  {
    title: 'MAKE A CLAIM',
    body: "Before you submit a claim, it's important to understand the level of cover you have.",
    linkText: 'Make a claim',
    imageUrl: DASHBOARD_NAV_IMAGE.CLAIMS,
    linkUrl: `${PATH.CLAIMS}/${policyPath}`,
    cardKey: 'claims'
  },
  {
    title: 'FREQUENTLY ASKED QUESTIONS',
    body: 'Looking for help? Browse our home insurance frequently asked questions.',
    linkText: 'FAQs',
    imageUrl: DASHBOARD_NAV_IMAGE.FAQ,
    linkUrl: EXTERNAL_PATH.FAQS,
    isExternal: true,
    cardKey: 'faqs'
  }
];

export const DashboardNavigation = (): JSX.Element => {
  const { pathname } = useLocation();
  const { policyPath } = getPolicyFromUrl(pathname);

  return (
    <div>
      <Row className="card-container">
        {getPageCards(policyPath).map((card) => (
          <Col key={card.title} className="card-column" xs={12} sm={4}>
            <DashboardNavigationItem
              title={card.title}
              body={card.body}
              linkText={card.linkText}
              imageUrl={card.imageUrl}
              linkUrl={card.linkUrl}
              isExternal={card.isExternal}
              cardKey={card.cardKey}
            />
          </Col>
        ))}
      </Row>
    </div>
  );
};
