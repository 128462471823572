import { Route, Redirect, RouteProps, useLocation } from 'react-router-dom';

import { PATH } from 'interfaces/pathTypes';

import { Loading } from '../loading';

interface ProtectedRouteProps extends RouteProps {
  isAuthenticated: boolean;
  isProcessing: boolean;
  component: (props: any) => JSX.Element;
}

export const ProtectedRoute = ({
  component: Component,
  path,
  isAuthenticated,
  isProcessing,
  ...rest
}: ProtectedRouteProps): JSX.Element => {
  const { search } = useLocation();
  const loginPath = path === PATH.QUOTES || path === PATH.CLAIMS ? path : '';

  if (isProcessing) {
    return <Loading heading="CHECKING YOUR DETAILS" text="Please wait while we gather your policy information" />;
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Component {...props} {...rest} path={path} />
        ) : (
          <Redirect to={`/login${loginPath}${search}`} />
        )
      }
    />
  );
};
