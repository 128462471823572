import { Card, Button } from 'react-bootstrap';
import { SvgIcon } from '@avantia/style-guide';
import type { HpSvgIconTypes } from '@avantia/style-guide';

import { GTM_EVENT_TYPES, Q_INTERACTION_TYPES, sendAnalytics } from 'analytics';
import { formatDate, getCoverTypeIcon } from 'components/utils';
import { QUOTE_STATUS, Quote as QuoteData } from 'interfaces/customerDataTypes';

import { QuoteStatusWidget } from './quoteStatus';

import './quote.scss';

interface QuoteInterface {
  quote: QuoteData;
}

export const Quote = ({ quote }: QuoteInterface): JSX.Element => {
  const handleClick = () => {
    sendAnalytics({
      event: GTM_EVENT_TYPES.QINTERACTION,
      detail: {
        qinteraction_type: Q_INTERACTION_TYPES.PRIMARY_ACTION_BUTTON_CLICK,
        qinteraction_locator: 'save_and_retrieve',
        qinteraction_value: 'open_quote'
      }
    });
    sendAnalytics({
      event: GTM_EVENT_TYPES.OPEN_QUOTE,
      detail: {
        quote_reference: quote.quoteRef,
        agreement_id: quote.agreementId
      }
    });
  };

  return (
    <Card className="quote-card">
      <SvgIcon type={getCoverTypeIcon(quote.coverType) as HpSvgIconTypes} size="medium" />
      <h2>{quote.coverType}</h2>
      <p className="price">{`£${quote.quotePrice}`}</p>
      <p className="bold-text">Status</p>
      <QuoteStatusWidget quoteStatus={quote.quoteStatus} />
      <p className="bold-text">Address insured</p>
      <p>{quote.riskPostcode}</p>
      <p className="bold-text">Quote reference</p>
      <p id="reference-text">{quote.quoteRef}</p>
      <p className="bold-text">Date saved</p>
      <p>{formatDate(quote.dateSaved)}</p>
      <p className="bold-text">Cover start date</p>
      <p>{formatDate(quote.startDate)}</p>
      <Button
        className="quote-link"
        disabled={quote.quoteStatus !== QUOTE_STATUS.AVAILABLE}
        href={quote.quoteLink}
        onClick={handleClick}
        id={`quote-link-${quote.quoteRef}`}
        target="_blank">
        <SvgIcon type={'document' as HpSvgIconTypes} size="very-tiny" className="quote-link-icon" />
        <p className="av-text-large quote-link-text">Open quote</p>
      </Button>
    </Card>
  );
};
