import { getEnumFlags, MicroTimer, toEnumFlags } from '@avantia/client-and-server-utilities';
import { createIsolatedPackageLoggerWithFlags } from '@avantia/logging-and-errors';

export enum HpClientDebugFlags {
  None = 0,
  Authentication = 2 << 0,
  Configuration = 2 << 1,
  RestApi = 2 << 2,
  TimeTaken = 2 << 3,
  UnitTests = 2 << 4,
  ReducerProcessor = 2 << 5,
  All = Authentication | Configuration | RestApi | TimeTaken | UnitTests | ReducerProcessor
}

const log = createIsolatedPackageLoggerWithFlags<HpClientDebugFlags>(
  'HpClientDebugFlags',
  HpClientDebugFlags.None,
  HpClientDebugFlags.All
);

const {
  debug: logDebug,
  info: logInfo,
  notice: logNotice,
  warn: logWarning,
  error: logError,
  critical: logCritical,
  setLogger: setLoggerForClient,
  getLogger: getLoggerForClient,
  setFlags: setLoggerFlagsForClient,
  getFlags: getLoggerFlagsForClient
} = log;

export {
  logDebug,
  logInfo,
  logNotice,
  logWarning,
  logError,
  logCritical,
  setLoggerForClient,
  getLoggerForClient,
  setLoggerFlagsForClient,
  getLoggerFlagsForClient
};

export function displayTimeTaken(description: string, timer: MicroTimer): void {
  logDebug(`${description}: took ${timer.elapsedTimeAsString()}`, HpClientDebugFlags.TimeTaken);
}

export function recordElapsedTime<ResultT>(description: string, action: () => ResultT): ResultT {
  const timer = new MicroTimer();
  const res = action();
  displayTimeTaken(description, timer);
  return res;
}

export function getClientDebugFlags(mode?: HpClientDebugFlags): string[] {
  return getEnumFlags(mode === undefined ? log.getFlags() : mode, HpClientDebugFlags as any);
}

export function toClientDebugFlags(debugWhich: string): HpClientDebugFlags {
  return toEnumFlags(debugWhich, HpClientDebugFlags as any);
}
