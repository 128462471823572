import { Card } from 'react-bootstrap';

import { getConfig } from 'config/configuration';
import type { DashboardNavImage } from 'interfaces/navigationTypes';

import './navigationCard.scss';
import { ArrowLink } from 'components/common/arrowLink';

export interface NavigationCardProps {
  title: string;
  body: string;
  linkText: string;
  imageUrl: DashboardNavImage;
}

const { assetsUrl } = getConfig();

export const NavigationCard = ({ title, body, linkText, imageUrl }: NavigationCardProps): JSX.Element => {
  const url = assetsUrl + imageUrl;

  return (
    <Card className="dashboard-card">
      <Card.Img variant="top" src={url} />
      <h4 className="heading-text">{title}</h4>
      <p className="body-text">{body}</p>
      <div className="link-text">
        <ArrowLink linkText={linkText} />
      </div>
    </Card>
  );
};
