import { HpAlert } from 'components/common/alert';
import { PaymentInformation } from '../paymentInformation';

import './paymentSuccessPage.scss';
interface PaymentSuccessPageProps {
  handleClickEdit: () => void;
  cardNumber?: string | null;
  cardExpiry?: string | null;
}

export const PaymentSuccessPage = ({
  handleClickEdit,
  cardExpiry,
  cardNumber
}: PaymentSuccessPageProps): JSX.Element => {
  return (
    <div>
      <HpAlert
        header="New card added"
        message={<p className="alert-text">Thanks for updating your payment details</p>}
        iconType="check"
        alertLevel="success"
      />
      <PaymentInformation handleClickEdit={handleClickEdit} cardExpiry={cardExpiry} cardNumber={cardNumber} />
    </div>
  );
};
