import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { State, UserInterfaceState } from 'reducers/initialState';

export interface StateProps {
  userInterface: UserInterfaceState;
}

const ErrorPageComponent: React.FC = () => {
  return <p>Sorry, that page doesn&apos;t exist</p>;
};

const mapStateToProps = (state: State): StateProps => {
  const { userInterface } = state;
  return { userInterface };
};

const mapDispatchToProps = {};

export const ErrorPage = withRouter(connect(mapStateToProps, mapDispatchToProps)(ErrorPageComponent) as any);
