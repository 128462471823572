import { getConfig } from '../config/configuration';
import type { HpDispatchFunction } from './actionTypes';
import { fetchResolver } from './fetchResolver';
import { ErrorMap } from 'interfaces/errorTypes';

export interface EmailDocumentsPayload {
  documentArchiveId: number;
  documentTemplateId: number;
  emailAddress: string;
  historyId: number;
  policyBookletUrl: string;
  policyDetailsId: string;
  policyNumber: string;
  postcode: string;
  surname: string;
  title: string;
}

export interface EmailDocumentsResponse {
  success: boolean;
  policyNumber: string;
}

export function emailDocumentsAction(payload: EmailDocumentsPayload) {
  return (dispatch: HpDispatchFunction<ErrorMap | EmailDocumentsResponse>): Promise<void> | undefined => {
    dispatch({ type: 'EMAIL_DOCUMENTS_LOADING' });

    const { websiteEndpoint } = getConfig();
    const url = `${websiteEndpoint}/api/email/documents`;

    return fetchResolver<any>({
      options: { url, method: 'POST', description: `Email documents`, data: JSON.stringify(payload) },
      dispatch,
      successAction: () => {
        dispatch({ type: 'EMAIL_DOCUMENTS_SUCCESS' });
      },
      failedAction: (err) => {
        dispatch({ type: 'EMAIL_DOCUMENTS_ERROR', payload: err.message });
      }
    });
  };
}
