import { clearPaymentErrorAction } from 'actions/clearPaymentErrorAction';
import { getPaymentStatusAction } from 'actions/index';
import { Loading } from 'components/common/loading';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { PaymentErrorPage } from './paymentErrorPage';
import { PaymentFrame } from 'components/common/paymentFrame';
import { PaymentSuccessPage } from './paymentSuccessPage';
interface paymentPortalPageProps {
  renewalPaymentAmount?: number;
}

export const PaymentPortalPage = ({ renewalPaymentAmount }: paymentPortalPageProps): JSX.Element => {
  const paymentGuid = useAppSelector((state) => state.payments.paymentGuid);
  const isPaymentSetupLoading = useAppSelector((state) => state.payments.isPaymentSetupLoading);
  const isBraintreeLoading = useAppSelector((state) => state.payments.isBraintreeLoading);
  const isPaymentSuccess = useAppSelector((state) => state.payments.isPaymentSuccess);
  const paymentErrorCount = useAppSelector((state) => state.payments.paymentErrorCount);

  const dispatch = useAppDispatch();

  const paymentMessageListener = (message: MessageEvent<any>): void => {
    dispatch(getPaymentStatusAction(message));
  };

  useEffect(() => {
    window.removeEventListener('message', paymentMessageListener); // make sure we don't double up.
    window.addEventListener('message', paymentMessageListener);
    return () => {
      // Called when component unmounts
      dispatch(clearPaymentErrorAction());
    };
  }, []);

  if (isPaymentSetupLoading) {
    return <Loading heading="Loading payment page" text="Please wait while we load the payment page" />;
  }

  if (paymentErrorCount > 0) {
    return (
      <PaymentErrorPage
        paymentGuid={paymentGuid}
        paymentErrorCount={paymentErrorCount}
        isBraintreeLoading={isBraintreeLoading}
        buttonText="Make a payment"
        paymentTextAnnual="Enter your card details below. The total amount payable is "
      />
    );
  }

  if (isPaymentSuccess) {
    return <PaymentSuccessPage renewalPaymentAmount={renewalPaymentAmount} />;
  }

  return (
    <div>
      {isBraintreeLoading && (
        <Loading heading="Loading payment page" text="Please wait while we load the payment page" />
      )}
      {/* Render iFrame while loading  */}
      <PaymentFrame
        paymentGuid={paymentGuid}
        id={paymentErrorCount}
        isBraintreeLoading={isBraintreeLoading}
        buttonText="Make a payment"
        paymentTextAnnual="Enter your card details below. The total amount payable is "
      />
    </div>
  );
};
