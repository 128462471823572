import { HpAlert } from 'components/common/alert';
import { useAppSelector } from 'store/hooks';
import { getConfig } from 'config/configuration';

import './paymentSuccessPage.scss';
import { useGetScreenSize } from 'components/utils';

const { assetsUrl } = getConfig();

export const PaymentSuccessPage = (): JSX.Element => {
  const cardImageUrl = useAppSelector((state) => state.payments.cardImageUrl);
  const cardLastFourDigits = useAppSelector((state) => state.payments.cardLastFour);
  const cardType = useAppSelector((state) => state.payments.cardType);
  const { isMobile } = useGetScreenSize();

  return (
    <div className="payment-success-container">
      <HpAlert
        iconType="check"
        alertLevel="success"
        header="New card added"
        message={<p className="success-alert-text">Thanks for updating your payment details.</p>}
        className="payment-success-alert"
      />
      <p className="card-header">Paying with Card</p>
      <div className="card-details">
        <div className="image-container">
          <img src={cardImageUrl} alt="card-type" width={isMobile ? 36 : 48} height={isMobile ? 24 : 32} />
        </div>
        <div className="card-details-text">
          <p className="bold-text">Ending in {cardLastFourDigits}</p>
          <p>{cardType}</p>
        </div>
        <img
          src={assetsUrl + 'email/icons/green/circle-check.png'}
          alt="circle check"
          width={isMobile ? 32 : 48}
          height={isMobile ? 32 : 48}
          className="check-icon"
        />
      </div>
    </div>
  );
};
