import { isValid, getYear } from 'date-fns';

export const getYearString = (date: string): string => {
  const dateObj = new Date(date);
  if (isValid(dateObj)) {
    const yearNumber = getYear(dateObj);
    return String(yearNumber);
  }

  return 'N/A';
};
