export const PATH = {
  DASHBOARD: '/dashboard',
  QUOTES: '/quotes',
  CLAIMS: '/claims',
  DOCUMENTS: '/documents',
  SETTINGS: '/settings',
  LOGIN: '/login',
  RENEWAL_PAYMENT: '/renewal-payment',
  PAYMENT_MANAGEMENT_WEBCHAT: '/payment-management',
  PAYMENT_MANAGEMENT: '/customer-payment-management'
} as const;

export type Path = (typeof PATH)[keyof typeof PATH];

export const EXTERNAL_PATH = {
  CONTACT_US: 'https://www.homeprotect.co.uk/contact-us',
  FAQS: 'https://www.homeprotect.co.uk/faqs'
} as const;

export type ExternalPath = (typeof EXTERNAL_PATH)[keyof typeof EXTERNAL_PATH];

export type AllPaths = Path | ExternalPath;

export const LOGIN_PATH = {
  DEFAULT: '/',
  CLAIMS: '/claims',
  QUOTES: '/quotes'
} as const;

export type LoginPath = (typeof LOGIN_PATH)[keyof typeof LOGIN_PATH];
