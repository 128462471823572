import { POLICY_STATUS } from 'interfaces/customerDataTypes';
import type { PolicyStatus } from 'interfaces/customerDataTypes';

export const getPolicyStatusText = (policyStatus: PolicyStatus): string => {
  switch (policyStatus) {
    case POLICY_STATUS.ADJUSTED:
      return 'Policy adjusted';
    case POLICY_STATUS.RENEWED:
      return 'Policy renewed';
    case POLICY_STATUS.TIME_TO_RENEW:
      return 'Time to renew';
    case POLICY_STATUS.CANCELLED:
      return 'Policy cancelled';
    case POLICY_STATUS.CONTACT_US:
      return 'Contact us to renew';
    case POLICY_STATUS.EXPIRING:
      return 'Policy expiring';
    case POLICY_STATUS.PAYMENT_REQUIRED:
      return 'Payment required';
    default:
      return 'Policy active';
  }
};
