// @avantia common-code
import { MicroTimer, SMap } from '@avantia/client-and-server-utilities';
import { HpTemplateAction } from '../actions/actionTypes';
import { displayTimeTaken, HpClientDebugFlags, logInfo } from '../clientLogging';

export type HpHandlerFunction<StateT, ActionTypeT extends string> = (
  state: StateT,
  action: HpTemplateAction<any, ActionTypeT>,
  reducerAction: (state: StateT, action: HpTemplateAction<any, ActionTypeT>) => StateT
) => StateT;

export interface HpReducerProcessorAddOptions {
  formNameOverride?: string;
  isDuplicate?: boolean;
}

export class ReducerProcessor<StateT, ActionTypeT extends string> {
  private name: string;
  private actions: Map<ActionTypeT, any> = new Map<ActionTypeT, any>();
  private handlers: SMap<HpHandlerFunction<StateT, ActionTypeT>> = {};
  private formNameOverrides: SMap<string> = {};

  constructor(name: string) {
    this.name = name;
  }

  public add<ActionT extends HpTemplateAction<any, ActionTypeT>>(
    type: ActionTypeT,
    reducerAction: (state: StateT, action: ActionT) => StateT,
    options?: HpReducerProcessorAddOptions
  ): void {
    type ActionFunc = (state: StateT, action: ActionT) => StateT;
    const { formNameOverride, isDuplicate } = options || {};
    let action: ActionFunc = reducerAction;

    if (this.handlers[type]) {
      if (!isDuplicate) {
        throw new Error(`There ${this.name} reducer already contains the "${type}" action.`);
      }

      // create a wrapper that calls the previous action followed by the current one.
      const origAction: ActionFunc = this.actions.get(type);
      action = (state, action) => {
        const newState = origAction(state, action);
        return reducerAction(newState, action);
      };
    } else {
      this.handlers[type] = createHandler<StateT, ActionTypeT, ActionT>(type);
    }

    this.actions.set(type, action);

    if (formNameOverride && !this.formNameOverrides[type]) {
      this.formNameOverrides[type] = formNameOverride;
    }
  }

  public process(state: StateT, action: HpTemplateAction<any, ActionTypeT>): StateT {
    let newState = state;
    const { type } = action;
    if (this.actions.has(type)) {
      const actionFormName = action.payload ? action.payload.formName : undefined;
      const formName = this.formNameOverrides[type] || this.name;
      if (!actionFormName || actionFormName === formName) {
        const timer = new MicroTimer().start();
        const reducerAction: (state: StateT, action: HpTemplateAction<any, ActionTypeT>) => StateT =
          this.actions.get(type);

        logInfo(`Processing ${this.name} reducer "${type}" action.`, HpClientDebugFlags.ReducerProcessor);

        const handler = this.handlers[type];
        newState = handler(newState, action, reducerAction);

        displayTimeTaken(`Reducer handler ${type}`, timer);
      } else {
        logInfo(
          `The ${this.name} reducer is doing nothing for "${type}" action because action.formName (${actionFormName}) doesn't match ${formName}.`,
          HpClientDebugFlags.ReducerProcessor
        );
      }
    } else {
      logInfo(`There is nothing to do for ${this.name} reducer "${type}" action.`, HpClientDebugFlags.ReducerProcessor);
    }

    return newState;
  }
}

type HpReducerHandlerFunction<
  StateT,
  ActionTypeT extends string,
  ActionT extends HpTemplateAction<any, ActionTypeT>
> = (
  state: StateT,
  action: HpTemplateAction<any, ActionTypeT>,
  reducerAction: (state: StateT, action: ActionT) => StateT
) => StateT;

function createHandler<StateT, ActionTypeT extends string, ActionT extends HpTemplateAction<any, ActionTypeT>>(
  type: ActionTypeT
): HpReducerHandlerFunction<StateT, ActionTypeT, ActionT> {
  return (
    state: StateT,
    action: HpTemplateAction<any, ActionTypeT>,
    reducerAction: (state: StateT, action: ActionT) => StateT
  ): StateT => {
    if (action.type === type) {
      return reducerAction(state, action as ActionT);
    }

    return state;
  };
}
