import { useAppSelector } from 'store/hooks';

import type { HpChildComponentProps } from 'components/common/componentWithModal/componentWithModal';
import { DashboardNavigation } from 'components/navigation/dashboardNavigation';
import { ContactBlock } from 'components/navigation/contactBlock';
import { Policy } from './policy';

import './dashboard.scss';

export const Dashboard = ({ selectedPolicy, setShow }: HpChildComponentProps): JSX.Element => {
  const policies = useAppSelector((state) => state.customerData.policies);

  return (
    <div className="dashboard-container">
      <Policy
        selectedPolicy={selectedPolicy}
        onClickSelect={setShow}
        isMultiplePolicies={policies ? policies.length > 1 : false}
      />
      <DashboardNavigation />
      <ContactBlock />
    </div>
  );
};
