import { registerCustomSvgIcons } from '@avantia/style-guide';
import { ReactComponent as BarsIcon } from '@fortawesome/fontawesome-free/svgs/solid/bars.svg';
import { ReactComponent as FacebookIcon } from '@fortawesome/fontawesome-free/svgs/brands/facebook.svg';
import { ReactComponent as IdIcon } from '@fortawesome/fontawesome-free/svgs/solid/id-badge.svg';
import { ReactComponent as EnvelopeIcon } from '@fortawesome/fontawesome-free/svgs/solid/envelope.svg';
import { ReactComponent as ExclamationIcon } from '@fortawesome/fontawesome-free/svgs/solid/exclamation-circle.svg';
import { ReactComponent as DocumentIcon } from '@fortawesome/fontawesome-free/svgs/solid/file-alt.svg';
import { ReactComponent as CheckIcon } from '@fortawesome/fontawesome-free/svgs/solid/check.svg';
import { ReactComponent as CrossIcon } from '@fortawesome/fontawesome-free/svgs/solid/times.svg';
import { ReactComponent as PdfIcon } from '@fortawesome/fontawesome-free/svgs/solid/file-pdf.svg';
import { ReactComponent as AngleUpIcon } from '@fortawesome/fontawesome-free/svgs/solid/angle-up.svg';
import { ReactComponent as MapMarkerIcon } from '@fortawesome/fontawesome-free/svgs/solid/map-marker-alt.svg';
import { ReactComponent as CalendarCheckIcon } from '@fortawesome/fontawesome-free/svgs/solid/calendar-check.svg';
import { ReactComponent as CalendarCrossIcon } from '@fortawesome/fontawesome-free/svgs/solid/calendar-times.svg';
import { ReactComponent as CalendarPlusIcon } from '@fortawesome/fontawesome-free/svgs/solid/calendar-plus.svg';
import { ReactComponent as CreditCardIcon } from '@fortawesome/fontawesome-free/svgs/solid/credit-card.svg';
import { ReactComponent as RefreshIcon } from '@fortawesome/fontawesome-free/svgs/solid/redo.svg';
import { ReactComponent as ShieldIcon } from '@fortawesome/fontawesome-free/svgs/solid/shield-alt.svg';
import { ReactComponent as CrossCircleIcon } from '@fortawesome/fontawesome-free/svgs/solid/times-circle.svg';
import { ReactComponent as InfoIcon } from '@fortawesome/fontawesome-free/svgs/solid/info-circle.svg';
import { ReactComponent as PricePromiseIcon } from 'assets/svg/price-promise-icon.svg';
import { ReactComponent as CheckCircleIcon } from 'assets/svg/check-circle-blue.svg';
import { ReactComponent as PenIcon } from '@fortawesome/fontawesome-free/svgs/solid/pen.svg';
import { ReactComponent as EditSquareIcon } from '@fortawesome/fontawesome-free/svgs/solid/edit.svg';

export const registerCustomIcons = () => {
  registerCustomSvgIcons({
    bars: BarsIcon,
    facebook: FacebookIcon,
    envelope: EnvelopeIcon,
    'price-promise-icon': PricePromiseIcon,
    'id-badge': IdIcon,
    exclamation: ExclamationIcon,
    document: DocumentIcon,
    check: CheckIcon,
    cross: CrossIcon,
    'check-circle': CheckCircleIcon,
    pdf: PdfIcon,
    'angle-up': AngleUpIcon,
    'map-marker': MapMarkerIcon,
    'calendar-check': CalendarCheckIcon,
    'calendar-cross': CalendarCrossIcon,
    'calendar-plus': CalendarPlusIcon,
    'credit-card': CreditCardIcon,
    shield: ShieldIcon,
    refresh: RefreshIcon,
    'cross-circle': CrossCircleIcon,
    info: InfoIcon,
    pen: PenIcon,
    'edit-square': EditSquareIcon
  });
};
