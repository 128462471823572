import { Loading } from 'components/common/loading';
import { useAppSelector } from 'store/hooks';

import { LoginForm } from './loginForm';
import { LoginSuccess } from './loginSuccess';
import { HpSvgIconTypes } from '@avantia/style-guide';

export type LoginOptions = 'documents' | 'claims' | 'quotes';

export interface LoginPageProps {
  title: string;
  helperText: JSX.Element;
  tab: LoginOptions;
  path?: string;
}

export const LoginPage = ({ title, helperText, tab, path }: LoginPageProps): JSX.Element => {
  const isProcessing = useAppSelector((state) => state.login.loginProcessing);
  const isAuthLinkSuccess = useAppSelector((state) => state.login.authLinkSuccess);

  if (isAuthLinkSuccess) {
    return <LoginSuccess />;
  }

  if (!isProcessing) {
    return <LoginForm title={title} helperText={helperText} tab={tab} path={path} />;
  }

  return (
    <Loading
      heading="CHECKING YOUR DETAILS"
      text="Please wait while we gather your policy information"
      icon={'id-badge' as HpSvgIconTypes}
    />
  );
};
