import { configureDataDogRum } from '@avantia/third-party-monitoring/configureDataDogRum';
import type { HpLoggerFunction } from '@avantia/logging-and-errors';
import jQuery from 'jquery';
import * as ReactDOMClient from 'react-dom/client';
import { Provider } from 'react-redux';
import './assets/style/site.scss';
import App from './components/app';
import { getConfig } from './config/configuration';
import { logInfo as logInfoFunc, HpClientDebugFlags } from './clientLogging';
import { registerClientConfig } from './config/registerClientConfig';
import reportWebVitals from './reportWebVitals';
import store from './store/configureStore';
import { registerCustomIcons } from './registerCustomIcons';

declare const window: any;
window.jQuery = window.jQuery || jQuery;
window.$ = window.jQuery;

const config = getConfig();
registerClientConfig(config);

const { ddApplicationId, ddClientToken, ddRumEnabled, ddRumSessionRecordingEnabled, environment, releaseVersion } =
  config;

const logInfo: HpLoggerFunction = (msg) => logInfoFunc(msg, HpClientDebugFlags.Configuration);

configureDataDogRum({
  ddApplicationId,
  ddClientToken,
  ddRumEnabled,
  ddRumSessionRecordingEnabled,
  ddServiceName: 'homeprotect-customer-portal',
  environment,
  releaseVersion,
  ddDefaultPrivacyLevel: 'mask-user-input',
  logInfo
});

// Submodules can't import svg components, so we need to do so here.
registerCustomIcons();

const container = document.getElementById('root') as HTMLElement;
const root = ReactDOMClient.createRoot(container);
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
