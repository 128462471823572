import { SvgIcon } from '@avantia/style-guide';
import type { HpSvgIconTypes } from '@avantia/style-guide';
import { Modal } from 'react-bootstrap';

import { Button } from 'components/common/button';

import './renewalToggleModal.scss';

interface RenewalToggleModalProps {
  show: boolean;
  handleClose: () => void;
  toggleRenewal: () => void;
}

export const RenewalToggleModal = ({ show, handleClose, toggleRenewal }: RenewalToggleModalProps): JSX.Element => {
  return (
    <Modal show={show}>
      <div className="modal-header">
        <h2>TURN OFF AUTO-RENEWAL</h2>
        <a onClick={handleClose} data-testid="close-button" id="close-button">
          <SvgIcon type={'cross' as HpSvgIconTypes} size="small" />
        </a>
      </div>
      <p className="av-text-large">
        Your cover will end at the policy renewal date. Are you sure you want to turn off auto-renewal?
      </p>
      <div className="modal-actions">
        <Button handleClick={handleClose} text="NO" id="no-button" />
        <Button handleClick={toggleRenewal} className="yes-button" text="YES" id="yes-button" />
      </div>
    </Modal>
  );
};
