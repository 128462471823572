import { GTM_EVENT_TYPES, sendAnalytics } from 'analytics';
import { getConfig } from '../config/configuration';
import type { HpDispatchFunction } from './actionTypes';
import { fetchResolver } from './fetchResolver';
import { ErrorMap } from 'interfaces/errorTypes';

export function signOutAction() {
  return (dispatch: HpDispatchFunction<ErrorMap | { [key: string]: string }>): Promise<void> | undefined => {
    dispatch({ type: 'SIGN_OUT_LOADING' });

    const { websiteEndpoint } = getConfig();
    const url = `${websiteEndpoint}/api/sign-out`;

    return fetchResolver<any>({
      options: { url, method: 'POST', description: `Sign out` },
      dispatch,
      successAction: () => {
        sendAnalytics({ event: GTM_EVENT_TYPES.LOG_OFF });
        dispatch({ type: 'SIGN_OUT_SUCCESS' });
      },
      failedAction: () => {
        dispatch({ type: 'SIGN_OUT_ERROR' });
      }
    });
  };
}
