import { Policy, PolicyDetail, Quote } from 'interfaces/customerDataTypes';
import { FeatureToggleTypes, featureToggleTypes } from '../interfaces/featureToggle';

export interface UserInterfaceState {
  fetchError?: any;
  ajaxCallsInProgress: number;
  disableButtons: boolean;
}

export type FeatureToggleState = Map<FeatureToggleTypes, boolean>;

export interface LoginState {
  loginValidationErrors?: { [key: string]: string };
  loginProcessing: boolean;
  authLinkFailed: boolean;
  authLinkSuccess: boolean;
  loginError?: string;
}

export interface DocumentsState {
  emailDocumentsProcessing: boolean;
  emailDocumentsError: boolean;
  emailDocumentsSuccess: boolean;
}

export interface PaymentState {
  isPaymentSetupLoading: boolean;
  isBraintreeLoading: boolean;
  paymentGuid: string;
  isPaymentSuccess: boolean;
  paymentErrorCount: number;
  paymentReference?: string;
  paymentStatus?: string;
  isPaymentSetupError: boolean;
  cardLastFour?: string;
  cardType?: string;
  cardImageUrl?: string;
  cardExpiry?: string;
}

export interface CustomerDataState {
  policyDetailsLoading: boolean;
  policyDetailsError?: string;
  isPolicyDetailsError: boolean;
  selectedPolicy?: PolicyDetail;
  toggleAutoRenewLoading: boolean;
  customerInsuranceSummaryLoading: boolean;
  policies?: Policy[];
  quotes?: Quote[];
  customerInsuranceSummaryError: boolean;
  isAuthenticated: boolean;
  authLinkError?: string;
  isTokenError: boolean;
  toggleAutoRenewalError?: string;
}

export interface State {
  featureToggles: FeatureToggleState;
  userInterface: UserInterfaceState;
  login: LoginState;
  documents: DocumentsState;
  payments: PaymentState;
  customerData: CustomerDataState;
}

function setFeatureToggles(): FeatureToggleState {
  const featureToggleMap = new Map<FeatureToggleTypes, boolean>();
  featureToggleTypes.map((v) => featureToggleMap.set(v, false));
  return featureToggleMap;
}

// Split into different functions.
export function getInitialState(): State {
  return {
    featureToggles: setFeatureToggles(),
    userInterface: {
      fetchError: null,
      ajaxCallsInProgress: 0,
      disableButtons: false
    },
    login: {
      loginProcessing: false,
      authLinkFailed: false,
      authLinkSuccess: false
    },
    documents: {
      emailDocumentsProcessing: false,
      emailDocumentsError: false,
      emailDocumentsSuccess: false
    },
    payments: {
      isPaymentSetupLoading: false,
      isBraintreeLoading: true,
      paymentGuid: '',
      isPaymentSuccess: false,
      paymentErrorCount: 0,
      isPaymentSetupError: false
    },
    customerData: {
      policyDetailsLoading: false,
      isPolicyDetailsError: false,
      toggleAutoRenewLoading: false,
      customerInsuranceSummaryLoading: false,
      customerInsuranceSummaryError: false,
      isAuthenticated: false,
      isTokenError: false
    }
  };
}
