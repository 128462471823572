import { Accordion, Card } from 'react-bootstrap';
import type { HpSvgIconTypes } from '@avantia/style-guide';
import { useState } from 'react';
import { shallowEqual } from 'react-redux';

import { emailDocumentsAction } from 'actions/emailDocumentsAction';
import { Button } from 'components/common/button';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import { PolicyDetail, PolicyDocument } from 'interfaces/customerDataTypes';
import { AccordionToggle } from './accordionToggle';

import './emailAccordion.scss';

interface EmailAccordionProps {
  selectedPolicy: PolicyDetail;
  policyDocument: PolicyDocument;
}

export const EmailAccordion = ({ selectedPolicy, policyDocument }: EmailAccordionProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const { emailDocumentsProcessing, emailDocumentsError, emailDocumentsSuccess } = useAppSelector(
    ({ documents: { emailDocumentsProcessing, emailDocumentsError, emailDocumentsSuccess } }) => ({
      emailDocumentsProcessing,
      emailDocumentsError,
      emailDocumentsSuccess
    }),
    shallowEqual
  );
  const { emailAddress, riskPostcode, policyRef, historyId, title, surname, policyDetailsId } = selectedPolicy;
  const { documentArchiveId, documentTemplateId, policyBookletUrl } = policyDocument;
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const handleClick = () => {
    dispatch(
      emailDocumentsAction({
        emailAddress,
        postcode: riskPostcode,
        policyNumber: policyRef,
        documentArchiveId,
        documentTemplateId,
        policyBookletUrl,
        historyId,
        title,
        surname,
        policyDetailsId
      })
    );
    setIsDisabled(true);
  };

  // TODO implement correct loading/error screens when designs are complete
  const getAccordionBody = (): JSX.Element => {
    if (emailDocumentsSuccess && isDisabled) {
      return (
        <>
          <p className="bold">Thank you</p>
          <p>Your documents have been emailed to you.</p>
        </>
      );
    }

    if (emailDocumentsError && isDisabled) {
      return (
        <>
          <p className="bold">Error</p>
          <p>An error has occurred. Please try again later.</p>
        </>
      );
    }

    return (
      <>
        <p>We will send all the documents in this section to the address you have provided.</p>
        <p className="bold">{emailAddress}</p>
      </>
    );
  };

  const getButtonIcon = (): HpSvgIconTypes =>
    emailDocumentsProcessing && isDisabled ? 'check' : ('envelope' as HpSvgIconTypes);

  const getButtonText = (): string => (isDisabled ? 'Sent' : 'Send');

  return (
    <Accordion>
      <Card>
        <AccordionToggle eventKey="0" setIsDisabled={setIsDisabled} />
        <Accordion.Collapse eventKey="0">
          <Card.Body>
            {getAccordionBody()}
            <Button
              handleClick={handleClick}
              disabled={isDisabled}
              icon={getButtonIcon()}
              text={getButtonText()}
              id="email-button"
            />
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};
