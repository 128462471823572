import type { HpSvgIconTypes } from '@avantia/style-guide';
import { SvgIcon } from '@avantia/style-guide';
import classnames from 'classnames';
import { Alert, Col, Row } from 'react-bootstrap';

import './alert.scss';

type AlertLevel = 'error' | 'info' | 'success';

interface AlertProps {
  message: JSX.Element;
  iconType: HpSvgIconTypes;
  header?: string;
  alertLevel: AlertLevel;
  className?: string;
  id?: string;
}

const ALERT_STYLES: Record<AlertLevel, string> = {
  info: 'info-alert',
  error: 'error-alert',
  success: 'success-alert'
};

export const HpAlert = ({ id, message, iconType, header, alertLevel, className }: AlertProps): JSX.Element => {
  if (header) {
    return (
      <Alert className={classnames(ALERT_STYLES[alertLevel], className)} data-testid={id}>
        <Row className="header-row">
          <Col xs={1} className="header-icon mobile-col">
            <SvgIcon type={iconType} size="tiny" className="alert-icon" />
          </Col>
          <Col xs={11} className="header-text">
            <p className="bold">{header}</p>
          </Col>
        </Row>
        <Row>
          <Col xs={{ span: 11, offset: 1 }}>{message}</Col>
        </Row>
      </Alert>
    );
  }

  return (
    <Alert className={classnames(ALERT_STYLES[alertLevel], className)} data-testid={id}>
      <Row>
        <Col xs={1} className="mobile-col">
          <SvgIcon type={iconType} size="tiny" className="alert-icon" />
        </Col>
        <Col xs={11}>{message}</Col>
      </Row>
    </Alert>
  );
};
