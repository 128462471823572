import { combineReducers } from 'redux';
import { featureToggleReducer } from './featureToggleReducer';
import { userInterfaceReducer } from './userInterfaceReducer';
import { loginReducer } from './loginReducer';
import { documentsReducer } from './documentsReducer';
import { paymentsReducer } from './paymentsReducer';
import { customerDataReducer } from './customerDataReducer';

const rootReducer = combineReducers({
  // Add your reducer references here
  // e.g. feature: featureReducer
  userInterface: userInterfaceReducer,
  featureToggles: featureToggleReducer,
  login: loginReducer,
  documents: documentsReducer,
  payments: paymentsReducer,
  customerData: customerDataReducer
});

export default rootReducer;
