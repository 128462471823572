import { SvgIcon } from '@avantia/style-guide';
import type { HpSvgIconTypes } from '@avantia/style-guide';
import classNames from 'classnames';
import './button.scss';

interface ButtonProps {
  text: string;
  type?: 'submit' | 'reset' | 'button';
  disabled?: boolean;
  handleClick?: () => void;
  className?: string;
  icon?: HpSvgIconTypes;
  id?: string;
}

export const Button = ({
  text,
  type = 'button',
  handleClick,
  className,
  disabled = false,
  icon,
  id
}: ButtonProps): JSX.Element => {
  if (icon) {
    return (
      <button
        type={type}
        onClick={handleClick}
        className={classNames(className, 'icon-button')}
        disabled={disabled}
        id={id}>
        <SvgIcon type={icon} />
        <p className="icon-button-text">{text}</p>
      </button>
    );
  }

  return (
    <button
      type={type}
      onClick={handleClick}
      className={classNames(className, 'regular-button')}
      disabled={disabled}
      id={id}>
      <h3>{text}</h3>
    </button>
  );
};
