import { useState, useEffect } from 'react';
import styleVariables from '../app.scss';

function getIsMobile(): boolean {
  const { innerWidth: width } = window;
  const { 'max-width-mobile': maxWidthMobilePx } = styleVariables;
  const maxWidthMobile = maxWidthMobilePx.split('px')[0];
  return width <= maxWidthMobile;
}

function getIsTablet(): boolean {
  const { innerWidth: width } = window;
  const { 'max-width-tablet': maxWidthTabletPx, 'min-width-tablet': minWidthTabletPx } = styleVariables;
  const maxWidthTablet = maxWidthTabletPx.split('px')[0];
  const minWidthTablet = minWidthTabletPx.split('px')[0];
  return width > minWidthTablet && width <= maxWidthTablet;
}

interface ScreenSize {
  isMobile: boolean;
  isTablet: boolean;
}

export const useGetScreenSize = (): ScreenSize => {
  const [isMobile, setIsMobile] = useState(getIsMobile());
  const [isTablet, setIsTablet] = useState(getIsTablet());

  useEffect(() => {
    function handleResize() {
      setIsMobile(getIsMobile());
      setIsTablet(getIsTablet());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return { isMobile, isTablet };
};
