export const HP_MESSAGE_TYPE = {
  CLIENT_HEIGHT_CHANGE: 'clientHeightChange',
  PAYMENT_OUTCOME: 'paymentOutcome',
  PAYMENT_ATTEMPT: 'paymentAttempt',
  ADDRESS_FORM_DISPLAYED: 'addressFormDisplayed',
  BRAINTREE_LOADING: 'braintreeLoading',
  BRAINTREE_LOADED: 'braintreeLoaded',
  ERROR_PAGE_LOADED: 'errorPageLoaded'
};

export type HpMessageType = (typeof HP_MESSAGE_TYPE)[keyof typeof HP_MESSAGE_TYPE];

export type HpMessageContent = HpClientHeightChangeMessageData | HpPaymentOutcomeMessageData | HpMessageCommon;

export interface HpClientHeightChangeMessageData extends HpMessageCommon {
  height: number;
}

export interface HpPaymentOutcomeMessageData extends HpMessageCommon {
  paymentReference: string;
  paymentStatus: string;
  success: boolean;
  cardLastFour: string;
  cardType: string;
  cardImageUrl: string;
  expiryDate: string;
}

export interface HpMessage<DataT extends HpMessageContent = never> {
  origin: string;
  data: DataT;
}

export interface HpMessageCommon {
  type: HpMessageType;
}
