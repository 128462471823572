import { isTrue } from '@avantia/client-and-server-utilities';
import { lodash } from '@avantia/lodash';
import { toClientDebugFlags } from '../clientLogging';
import { ClientConfiguration } from './configurationTypes';

export function getConfig(): ClientConfiguration {
  const virtualPath = lodash.trimEnd('/', '/');
  const config: ClientConfiguration = {
    assetsUrl: 'https://assets.homeprotect.co.uk/',
    clientDebugFlags: toClientDebugFlags('None'),
    ddApplicationId: '8cafb31b-799e-4920-bfa3-f6dd695339c1',
    ddClientToken: 'pubb9e90244ddba915ca199e0978f1173d7',
    ddRumEnabled: isTrue('true'),
    ddRumSessionRecordingEnabled: isTrue('true'),
    developerModeEnabled: isTrue('false'),
    ddSampleRate: parseInt('100', 10),
    ddServiceName: 'homeprotectcustomerportal',
    ddTrackInteractions: isTrue('true'),
    environment: 'liv',
    homeProtectUrl: 'https://homeprotect.co.uk',
    logoPath: 'website/homeprotect_final_logo.svg',
    paymentPortalUrl: 'https://payment.homeprotect.co.uk',
    releaseVersion: '1.0.90.172051',
    websiteEndpoint: virtualPath,
    claimsPortalUrl: 'https://claim.homeprotect.co.uk/?customerDetailsKey='
  };

  return config;
}
