import { format, isValid } from 'date-fns';

export const formatDate = (date: string | Date): string => {
  const dateObj = new Date(date);
  if (isValid(dateObj)) {
    return format(dateObj, 'd MMM yyyy');
  }

  return 'N/A';
};
