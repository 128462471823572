import type { EmailDocumentsResponse } from 'actions/emailDocumentsAction';
import type { ErrorMap } from 'interfaces/errorTypes';

import { HpAction, HpTemplateActionTypes } from '../actions/actionTypes';
import { DocumentsState, getInitialState } from './initialState';
import { ReducerProcessor } from './reducerProcessor';

const processor = new ReducerProcessor<DocumentsState, HpTemplateActionTypes>('documents');

processor.add('EMAIL_DOCUMENTS_LOADING', (state) => {
  return {
    ...state,
    emailDocumentsProcessing: true
  };
});

processor.add('EMAIL_DOCUMENTS_ERROR', (state) => {
  return {
    ...state,
    emailDocumentsProcessing: false,
    emailDocumentsError: true
  };
});

processor.add('EMAIL_DOCUMENTS_SUCCESS', (state) => {
  return {
    ...state,
    emailDocumentsProcessing: false,
    emailDocumentsSuccess: true
  };
});

export function documentsReducer(
  state: DocumentsState = getInitialState().documents,
  action: HpAction<EmailDocumentsResponse | ErrorMap>
): DocumentsState {
  return processor.process(state, action);
}
