import { HpDispatchFunction } from './actionTypes';

export function getPaymentGuidFromUrl(pathname) {
  return (dispatch: HpDispatchFunction<string>): void => {
    const pathArray = pathname.split('/');
    const paymentGuidIndex = pathArray.indexOf('payment-management') + 1;
    const paymentGuid = pathArray[paymentGuidIndex];

    dispatch({ type: 'PAYMENT_GUID_SUCCESS', payload: paymentGuid });
  };
}
