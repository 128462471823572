import { Link } from 'react-router-dom';

import { ExternalLink } from 'components/common/externalLink';
import type { DashboardNavImage } from 'interfaces/navigationTypes';

import { NavigationCard } from './navigationCard';

import './dashboardNavigationItem.scss';

export interface DashboardNavigationItemProps {
  title: string;
  body: string;
  linkText: string;
  imageUrl: DashboardNavImage;
  linkUrl: string;
  isExternal?: boolean;
  cardKey: string;
}

export const DashboardNavigationItem = ({
  title,
  body,
  linkText,
  imageUrl,
  linkUrl,
  isExternal = false,
  cardKey
}: DashboardNavigationItemProps): JSX.Element => {
  if (isExternal) {
    return (
      <ExternalLink className="card-link" href={linkUrl} id={`${cardKey}-card-link`}>
        <NavigationCard title={title} body={body} linkText={linkText} imageUrl={imageUrl} />
      </ExternalLink>
    );
  }

  return (
    <Link to={linkUrl} id={`${cardKey}-card-link`} className="card-link">
      <NavigationCard title={title} body={body} linkText={linkText} imageUrl={imageUrl} />
    </Link>
  );
};
