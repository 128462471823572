import type { HpSvgIconTypes } from '@avantia/style-guide';
import { HpAlert } from 'components/common/alert';

import './loginError.scss';
import { ExternalLink } from 'components/common/externalLink';
import { EXTERNAL_PATH } from 'interfaces/pathTypes';

interface LoginErrorProps {
  error?: string;
}

interface LoginErrrorContent {
  message: JSX.Element;
  header?: string;
}

export const LoginError = ({ error }: LoginErrorProps) => {
  const getUserMessage = (code?: string): LoginErrrorContent => {
    switch (code) {
      case 'CPS016':
        return {
          message: (
            <p className="error-text">
              Sorry, the details you provided cannot be found. Please try again or{' '}
              <ExternalLink href={EXTERNAL_PATH.CONTACT_US} id="contact-us">
                get in contact with us here
              </ExternalLink>
            </p>
          )
        };
      case 'CPS006':
      case 'CPS017':
        return {
          header: 'Sorry, this link is no longer valid.',
          message: <p className="error-text">Please request a new secure link, by entering your details below.</p>
        };
      default:
        return {
          message: <p className="error-text">We are having trouble connecting to our server. Please try again later.</p>
        };
    }
  };

  const { message, header } = getUserMessage(error ? error?.split(':')[0] : undefined);

  return (
    <HpAlert
      message={message}
      iconType={'exclamation' as HpSvgIconTypes}
      alertLevel="error"
      className="error-alert"
      header={header}
    />
  );
};
