// @avantia common-code
import { ensureDefined } from '@avantia/client-and-server-utilities';
import { ClientConfiguration, RegisterConfigurationActionFunc } from './configurationTypes';

let cachedConfig: ClientConfiguration | undefined;
let configRegistrations: RegisterConfigurationActionFunc[] = [];

export function registerClientConfig(config: ClientConfiguration): void {
  cachedConfig = ensureDefined(config, 'config');

  if (configRegistrations.length > 0) {
    const registrations = [...configRegistrations];
    configRegistrations = [];
    registrations.forEach((action) => action(config));
  }
}

/**
 * This allows setup actions that need configuration to be processed when the config is initialized.
 * Otherwise we can end up with race conditions.
 * @param action the action to perform using the configuration.
 */
export function registerConfigurationAction(action: RegisterConfigurationActionFunc): void {
  if (cachedConfig) {
    action(cachedConfig);
  } else {
    configRegistrations.push(action);
  }
}
