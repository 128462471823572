import { SMap } from '@avantia/client-and-server-utilities';
import { getConfig } from 'config/configuration';
import { memo } from 'react';
import { useAppSelector } from 'store/hooks';

const { paymentPortalUrl } = getConfig();

interface HpFrameProps {
  paymentGuid: string;
  id: number;
  isBraintreeLoading: boolean;
  buttonText: string;
  showPaymentHeader?: boolean;
  paymentTextAnnual?: string;
  subHeadingText?: string;
}

export const paymentIFrameId = 'payment';

export const PaymentFrame = memo(function PaymentFrame({
  paymentGuid,
  id,
  isBraintreeLoading,
  buttonText,
  showPaymentHeader = true,
  paymentTextAnnual,
  subHeadingText
}: HpFrameProps) {
  const isPaymentSetupError = useAppSelector((state) => state.payments.isPaymentSetupError);
  const style: React.CSSProperties = {};
  const formData: SMap<any> = {
    footer: false,
    showPaymentHeader,
    initiatorUrl: document.URL,
    style,
    cardholder: true,
    buttonText,
    paymentTextAnnual,
    subHeadingText
  };
  let queryString = btoa(JSON.stringify(formData));
  queryString = `config=${encodeURIComponent(queryString)}`;

  const getFrameHeight = (): number => {
    if (isPaymentSetupError) {
      return 250;
    }

    // Hide iframe while loading
    if (isBraintreeLoading) {
      return 0;
    }

    return 1500;
  };

  return (
    <iframe
      data-testid="payment-iframe"
      id={paymentIFrameId + id}
      name={paymentIFrameId}
      title={paymentIFrameId}
      src={`${paymentPortalUrl}/makeapayment/${paymentGuid}?${queryString}&ts=${new Date().getTime()}`}
      width="100%"
      height={getFrameHeight()}
      scrolling="no"
      style={{ border: 'none', marginTop: '-16px' }}></iframe>
  );
});
