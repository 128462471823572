import { Redirect, useLocation } from 'react-router-dom';

import { useAppDispatch } from 'store/hooks';

import { PATH } from 'interfaces/pathTypes';
import { signOutAction } from 'actions/signOutAction';
import { getSignOutUrl } from 'components/utils';
import type { Policy, Quote } from 'interfaces/customerDataTypes';

interface IsNoDataProps {
  policies?: Policy[];
  quotes?: Quote[];
  checkPolicies?: boolean;
  checkQuotes?: boolean;
}

export const useIsNoData = ({
  policies,
  quotes,
  checkPolicies = false,
  checkQuotes = false
}: IsNoDataProps): JSX.Element | undefined => {
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();

  if (checkPolicies && (!policies || (policies && policies.length === 0))) {
    return <Redirect to={PATH.QUOTES} />;
  }

  if (checkQuotes && (!quotes || (quotes && quotes.length === 0))) {
    return <Redirect to={PATH.DASHBOARD} />;
  }

  if ((!policies || (policies && policies.length === 0)) && (!quotes || (quotes && quotes.length === 0))) {
    dispatch(signOutAction());
    return <Redirect to={getSignOutUrl(pathname)} />;
  }
};
