import { HpSvgIconTypes } from '@avantia/style-guide';
import { HpAlert } from 'components/common/alert';
import { PaymentFrame } from 'components/common/paymentFrame';
import './paymentErrorPage.scss';

interface PaymentErrorPageProps {
  paymentGuid: string;
  paymentErrorCount: number;
  isBraintreeLoading: boolean;
  buttonText: string;
  paymentTextAnnual: string;
}

export const PaymentErrorPage = ({
  paymentGuid,
  paymentErrorCount,
  isBraintreeLoading,
  buttonText,
  paymentTextAnnual
}: PaymentErrorPageProps): JSX.Element => {
  return (
    <div>
      <HpAlert
        id="payment-error-alert"
        header="Payment declined"
        message={
          <p className="payment-status-message">
            Use a different payment card and try again, or contact your bank for more information.
          </p>
        }
        alertLevel="error"
        iconType={'exclamation' as HpSvgIconTypes}
      />
      <PaymentFrame
        paymentGuid={paymentGuid}
        id={paymentErrorCount}
        isBraintreeLoading={isBraintreeLoading}
        buttonText={buttonText}
        paymentTextAnnual={paymentTextAnnual}
      />
    </div>
  );
};
