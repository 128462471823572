import queryString from 'query-string';
import { default as cookies } from 'js-cookie';

import { getConfig } from 'config/configuration';
import { DOCUMENT_TYPE_CODE, DocumentTypeCode } from 'interfaces/customerDataTypes';

const { environment } = getConfig();

interface DocumentDownloadProps {
  emailAddress: string;
  documentArchiveId: number;
  policyBookletUrl: string;
  documentTemplateId: number;
  historyId: number;
  policyDetailsId: string;
  policyRef: string;
}

export const DOCUMENT_URL: Record<DocumentTypeCode, string> = {
  [DOCUMENT_TYPE_CODE.PURCHASED_DOC]: '/api/document/download?',
  [DOCUMENT_TYPE_CODE.RENEWAL_INVITE_DOC]: '/api/document/download?',
  [DOCUMENT_TYPE_CODE.RENEWED_DOC]: '/api/document/download?',
  [DOCUMENT_TYPE_CODE.BOOKLET]: '/api/document/booklet/download?',
  [DOCUMENT_TYPE_CODE.ADJUSTED_DOC]: '/api/document/download?'
};

const getQueryString = (props: DocumentDownloadProps, docType: DocumentTypeCode): string => {
  const sid = cookies.get('connect.sid');
  if (docType === DOCUMENT_TYPE_CODE.BOOKLET) {
    return queryString.stringify({
      policyBookletUrl: props.policyBookletUrl,
      policyRef: props.policyRef
    });
  }

  return queryString.stringify({
    emailAddress: props.emailAddress,
    documentArchiveId: props.documentArchiveId,
    documentTemplateId: props.documentTemplateId,
    historyId: props.historyId,
    policyDetailsId: props.policyDetailsId,
    policyRef: props.policyRef,
    sid: sid || ''
  });
};

export function getLinkUrl(props: DocumentDownloadProps, docType: DocumentTypeCode): string {
  const queryText = getQueryString(props, docType);

  if (environment === 'developer') {
    return `http://localhost:3001${DOCUMENT_URL[docType] + queryText}`;
  }

  return DOCUMENT_URL[docType] + queryText;
}
