import type { HpSvgIconTypes } from '@avantia/style-guide';
import { Form } from 'react-bootstrap';
import { HTMLInputTypeAttribute, ChangeEvent, KeyboardEventHandler } from 'react';

import { HpAlert } from '../alert';
import './textInputControl.scss';

interface TextInputControlProps {
  label: string;
  type?: HTMLInputTypeAttribute;
  placeholder?: string;
  value: string;
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  handleBlur?: (e: ChangeEvent<HTMLInputElement>) => void;
  handleKeyDown?: KeyboardEventHandler<HTMLInputElement>;
  name: string;
  error?: string;
  fullWidth?: boolean;
}

export const TextInputControl = ({
  label,
  type = 'text',
  placeholder,
  value,
  handleChange,
  handleBlur,
  handleKeyDown,
  name,
  error,
  fullWidth = true
}: TextInputControlProps): JSX.Element => {
  return (
    <Form.Group className="login-group">
      <Form.Label id="login-label">{label}</Form.Label>
      <Form.Control
        id={fullWidth ? 'login-input-full-width' : 'login-input'}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
        name={name}
        isInvalid={!!error}
      />
      <Form.Control.Feedback type="invalid">
        {error && (
          <HpAlert
            iconType={'exclamation' as HpSvgIconTypes}
            message={<p className="error-text">{error}</p>}
            alertLevel="error"
            className="error-alert"
          />
        )}
      </Form.Control.Feedback>
    </Form.Group>
  );
};
