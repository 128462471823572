import { getConfig } from '../config/configuration';
import type { HpDispatchFunction } from './actionTypes';
import { fetchResolver } from './fetchResolver';
import type { AuthType } from 'components/utils/processAuthLink';
import { GTM_EVENT_TYPES, sendAnalytics } from 'analytics';
import { ErrorMap } from 'interfaces/errorTypes';
import { Policy, Quote } from 'interfaces/customerDataTypes';

export interface CustomerInsuranceSummaryPayload {
  authToken: string;
  authType?: AuthType;
}

export interface CustomerInsuranceSummaryResponse {
  policies?: Policy[];
  quotes?: Quote[];
  success: boolean;
  errors?: ErrorMap;
}

interface RequestProps {
  dispatch: HpDispatchFunction<CustomerInsuranceSummaryResponse>;
  url: string;
  authType?: AuthType;
}

const authenticationRequest = ({ dispatch, url, authType }: RequestProps): Promise<void> | undefined => {
  return fetchResolver({
    options: { url, method: 'GET', description: `Get policies and quotes` },
    dispatch,
    successAction: (response) => {
      const quotes = (response as CustomerInsuranceSummaryResponse).quotes;
      const policies = (response as CustomerInsuranceSummaryResponse).policies;
      sendAnalytics({
        event: GTM_EVENT_TYPES.LOGGED_IN,
        detail: {
          auth_type: authType,
          policy_count: policies ? policies.length : 0,
          quote_count: quotes ? quotes.length : 0
        }
      });
      dispatch({ type: 'CUSTOMER_INSURANCE_SUMMARY_SUCCESS', payload: response });
    },
    failedAction: (err) => {
      dispatch({ type: 'CUSTOMER_INSURANCE_SUMMARY_ERROR', payload: err.message });
    }
  });
};

export function getCustomerInsuranceSummaryAction(payload: CustomerInsuranceSummaryPayload) {
  return (dispatch: HpDispatchFunction<CustomerInsuranceSummaryResponse>): Promise<void> | undefined => {
    const { websiteEndpoint } = getConfig();
    const { authToken, authType } = payload;

    dispatch({ type: 'CUSTOMER_INSURANCE_SUMMARY_LOADING' });

    if (!authToken) {
      return authenticationRequest({
        dispatch,
        url: `${websiteEndpoint}/api/customer/summary/session-token`,
        authType
      });
    }

    return authenticationRequest({
      dispatch,
      url: `${websiteEndpoint}/api/customer/summary/${authType}/url-token/${encodeURIComponent(authToken)}`,
      authType
    });
  };
}
