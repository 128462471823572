import type { ErrorMap } from 'interfaces/errorTypes';

import { getConfig } from '../config/configuration';
import type { HpDispatchFunction } from './actionTypes';
import { fetchResolver } from './fetchResolver';

interface ClaimsPortalRedirectProps {
  emailAddress?: string;
  postcode?: string;
}

interface RedirectProps {
  url: string;
  openInNewTab: boolean;
}

const redirectToUrl = ({ url, openInNewTab }: RedirectProps): void => {
  setTimeout(() => {
    const link = document.createElement('a');
    link.href = url;
    if (openInNewTab) {
      link.target = '_blank';
    }

    document.body.appendChild(link);
    link.click();
    setTimeout(() => {
      document.body.removeChild(link);
    }, 100);
  }, 1000);
};

export function redirectToClaimsPortalAction(payload: ClaimsPortalRedirectProps) {
  return (dispatch: HpDispatchFunction<ErrorMap | { [key: string]: string }>): Promise<void> | undefined => {
    dispatch({ type: 'CLAIMS_REDIRECT_LOADING' });

    const { websiteEndpoint, claimsPortalUrl } = getConfig();
    const url = `${websiteEndpoint}/api/make-claim`;

    if (claimsPortalUrl.indexOf('?') > -1) {
      return fetchResolver<any>({
        options: { url, data: payload, method: 'POST', description: `Redirect to claims` },
        dispatch,
        successAction: (response) => {
          const { key } = response;
          const url = `${claimsPortalUrl}${key}`;
          redirectToUrl({ openInNewTab: false, url });
          dispatch({ type: 'CLAIMS_REDIRECT_SUCCESS' });
        },
        failedAction: () => {
          dispatch({ type: 'CLAIMS_REDIRECT_ERROR' });
        }
      });
    } else {
      redirectToUrl({ openInNewTab: false, url: claimsPortalUrl });
    }
  };
}
