import { Card } from 'react-bootstrap';

import { getConfig } from 'config/configuration';
import type { PageNavImage } from 'interfaces/navigationTypes';

import './navigationCard.scss';

export interface NavigationCardProps {
  title: string;
  imageUrl: PageNavImage;
}

const { assetsUrl } = getConfig();

export const NavigationCard = ({ title, imageUrl }: NavigationCardProps): JSX.Element => {
  const url = assetsUrl + imageUrl;

  return (
    <Card className="page-card">
      <Card.Img variant="top" src={url} />
      <h4 className="card-text">{title}</h4>
    </Card>
  );
};
