import { SvgIcon } from '@avantia/style-guide';
import { PolicyField } from 'components/common/policyField';
import { useAppSelector } from 'store/hooks';
import './paymentSuccessPage.scss';

interface PaymentSuccessPageProps {
  renewalPaymentAmount?: number;
}

export const PaymentSuccessPage = ({ renewalPaymentAmount }: PaymentSuccessPageProps): JSX.Element => {
  const paymentReference = useAppSelector((state) => state.payments.paymentReference);
  return (
    <div className="payment-success">
      <SvgIcon type="check-circle-large" className="check-circle-large-success" />
      <h2>Thank you for your payment</h2>
      <p>
        A receipt will be sent to your registered email. Please allow up to 5 days for this payment to be reflected on
        your account.
      </p>
      <PolicyField label="Amount paid" value={<p>£{renewalPaymentAmount}</p>} />
      <PolicyField label="Payment reference" value={<p>{paymentReference}</p>} />
    </div>
  );
};
