import { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import { sendAnalytics, GTM_EVENT_TYPES } from 'analytics';
import { useIsNoData } from 'components/utils';
import { useAppSelector } from 'store/hooks';
import { ContactBlock } from 'components/navigation/contactBlock';
import { useGetScreenSize } from 'components/utils';

import { Quote } from './quote';

import './quotes.scss';
import { PATH } from 'interfaces/pathTypes';

export const Quotes = (): JSX.Element => {
  const quotes = useAppSelector((state) => state.customerData.quotes);
  const redirect = useIsNoData({ quotes, checkQuotes: true });
  const { isTablet, isMobile } = useGetScreenSize();

  useEffect(() => {
    sendAnalytics({
      event: GTM_EVENT_TYPES.PAGE_VIEW,
      detail: { virtualpage_title: 'Quotes', virtualpage: PATH.QUOTES, virtualpage_url: document.location.href }
    });
  }, []);

  if (redirect) {
    return redirect;
  }

  return (
    <Container className="page-container" fluid={isTablet || isMobile}>
      <div className="quotes-container">
        <Row sm={2} xs={1} lg={3}>
          {quotes &&
            quotes.map((quote) => (
              <Col key={quote.quoteRef} data-testid="quote-card">
                <Quote quote={quote} />
              </Col>
            ))}
        </Row>
      </div>
      <ContactBlock />
    </Container>
  );
};
