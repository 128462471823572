import { RenewalMessages } from './renewalMessages';
import { RenewalToggle } from './renewalToggle';

import './autoRenewalSettings.scss';
import { PolicyDetail } from 'interfaces/customerDataTypes';

interface AutoRenewalSettingsProps {
  selectedPolicy: PolicyDetail;
}

export const AutoRenewalSettings = ({ selectedPolicy }: AutoRenewalSettingsProps): JSX.Element => {
  const { stopRenewal } = selectedPolicy;
  return (
    <div className="auto-renewal-settings">
      <h2>AUTO-RENEWAL PREFERENCE</h2>
      <RenewalMessages isAutoRenewal={!stopRenewal} />
      <RenewalToggle isAutoRenewal={!stopRenewal} selectedPolicy={selectedPolicy} />
    </div>
  );
};
