import { Row, Col } from 'react-bootstrap';

import { DOCUMENT_TYPE_CODE, PolicyDetail, PolicyDocument } from 'interfaces/customerDataTypes';
import { DocumentCard } from './documentCard';
import { EmailAccordion } from './emailAccordion';

import './documentsGroup.scss';
interface DocumentsGroupProps {
  name: string;
  selectedPolicy: PolicyDetail;
  policyDocument: PolicyDocument;
  showAccordion?: boolean;
}

export const DocumentsGroup = ({
  name,
  selectedPolicy,
  policyDocument,
  showAccordion = true
}: DocumentsGroupProps): JSX.Element => {
  return (
    <div>
      <p id="bold-text" data-testid="group-name">
        {name}
      </p>
      <Row>
        <Col xs={12} sm={6} className="document-col">
          <DocumentCard
            policyDocument={policyDocument}
            selectedPolicy={selectedPolicy}
            documentType={policyDocument.documentType}
          />
        </Col>
        <Col xs={12} sm={6} className="document-col">
          <DocumentCard
            policyDocument={policyDocument}
            selectedPolicy={selectedPolicy}
            documentType={DOCUMENT_TYPE_CODE.BOOKLET}
          />
        </Col>
      </Row>
      {showAccordion && (
        <Row className="email-accordion">
          <Col>
            <EmailAccordion selectedPolicy={selectedPolicy} policyDocument={policyDocument} />
          </Col>
        </Row>
      )}
    </div>
  );
};
