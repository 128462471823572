import { useState } from 'react';

import { toggleAutoRenewalStatusAction } from 'actions/toggleAutoRenewalStatusAction';
import { getConfig } from 'config/configuration';
import { PolicyDetail } from 'interfaces/customerDataTypes';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { RenewalToggleModal } from './renewalToggleModal';

import './renewalToggle.scss';

const { assetsUrl } = getConfig();

interface RenewalToggleProps {
  isAutoRenewal: boolean;
  selectedPolicy: PolicyDetail;
}

export const RenewalToggle = ({ isAutoRenewal, selectedPolicy }: RenewalToggleProps): JSX.Element => {
  const [show, setShow] = useState(false);
  const isToggleLoading = useAppSelector((state) => state.customerData.toggleAutoRenewLoading);
  const isError = useAppSelector((state) => state.customerData.toggleAutoRenewalError);
  const dispatch = useAppDispatch();
  const { allowPolicyMaintenance } = selectedPolicy;

  const handleClose = () => {
    setShow(false);
  };

  const toggleRenewal = () => {
    dispatch(
      toggleAutoRenewalStatusAction({
        isAutoRenewal: !isAutoRenewal,
        selectedPolicy
      })
    );
    setShow(false);
  };

  const handleSwitchClick = () => {
    if (isAutoRenewal) {
      setShow(true);
    } else {
      toggleRenewal();
    }
  };

  return (
    <>
      {isToggleLoading ? (
        <div className="loading">
          <img src={assetsUrl + 'website/page-loading.gif'} alt="Loading dots"></img>
          <p className="loading-label">Please wait while we opt you {isAutoRenewal ? 'out' : 'in'}</p>
        </div>
      ) : (
        <div className={allowPolicyMaintenance ? 'toggle' : 'disabled-toggle'}>
          <div className="switch" id="auto-renew-toggle">
            <input
              type="checkbox"
              id="auto-renew-switch"
              checked={isAutoRenewal}
              onChange={handleSwitchClick}
              disabled={!allowPolicyMaintenance}
              data-testid="toggle-auto-renew"
            />
            <label htmlFor="auto-renew-switch"></label>
          </div>
          <p className="toggle-label">Automatically renew this policy</p>
        </div>
      )}
      {isError ? (
        <>
          <div>
            <p className="error-label">An error has occurred. Please try again later.</p>
          </div>
        </>
      ) : (
        <></>
      )}
      <RenewalToggleModal show={show} toggleRenewal={toggleRenewal} handleClose={handleClose} />
    </>
  );
};
