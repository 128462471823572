import { SvgIcon } from '@avantia/style-guide';
import type { HpSvgIconTypes } from '@avantia/style-guide';

import './renewalMessages.scss';

interface RenewalMessagesProps {
  isAutoRenewal: boolean;
}

interface RenewalMessage {
  icon: string;
  text: string;
}

const WILL_AUTO_RENEW: RenewalMessage[] = [
  {
    icon: 'shield',
    text: "You'll remain protected for the next policy year"
  },
  {
    icon: 'refresh',
    text: "Your policy will renew automatically if you're happy with the price and cover - no need to give us a call"
  },
  {
    icon: 'calendar-plus',
    text: "We'll write to you around 30 days before renewal and you'll have an additional 14-day cooling off period to make changes to your policy"
  }
];

const NOT_AUTO_RENEW: RenewalMessage[] = [
  {
    icon: 'cross',
    text: "Cover will end at the renewal date, and you'll no longer be protected"
  },
  {
    icon: 'cross',
    text: 'Your policy will not renew automatically unless you get in touch with us'
  }
];

export const RenewalMessages = ({ isAutoRenewal }: RenewalMessagesProps): JSX.Element => {
  const renewalMessageList = isAutoRenewal ? WILL_AUTO_RENEW : NOT_AUTO_RENEW;
  const styles = isAutoRenewal ? 'will-auto-renew-messages' : 'not-auto-renew-messages';
  return (
    <div className={styles}>
      <p className="warnings-title av-text-large">When it&apos;s time to renew</p>
      <div>
        {renewalMessageList.map((message, index) => (
          <div key={index} className="message">
            <SvgIcon type={message.icon as HpSvgIconTypes} size="very-tiny" />
            <p id={`helper-text-${index}`}>{message.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
};
