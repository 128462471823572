import { useState, useEffect } from 'react';
import type { Dispatch, SetStateAction } from 'react';
import { Container } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

import { useAppSelector } from 'store/hooks';
import { isPolicySelected } from '../multiplePolicyModal/utils/isPolicySelected';
import { MultiplePolicyModal } from '../multiplePolicyModal';
import { useIsNoData, useGetScreenSize } from 'components/utils';
import { sendAnalytics, GTM_EVENT_TYPES, GTM_TITLE } from 'analytics';
import { Path } from 'interfaces/pathTypes';
import type { Policy, PolicyDetail } from 'interfaces/customerDataTypes';
import { Loading } from '../loading';
import { useSetSelectedPolicy } from '../multiplePolicyModal/utils/useSetSelectedPolicy';

import './componentWithModal.scss';

export interface HpChildComponentProps {
  selectedPolicy: PolicyDetail;
  setShow: Dispatch<SetStateAction<boolean>>;
}

interface ComponentWithModalProps {
  childComponent: ({ selectedPolicy, setShow }: HpChildComponentProps) => JSX.Element;
  path: Path;
}

export const ComponentWithModal = ({ childComponent: Component, path }: ComponentWithModalProps): JSX.Element => {
  const { pathname } = useLocation();
  const policies = useAppSelector((state) => state.customerData.policies);
  const selectedPolicy = useAppSelector((state) => state.customerData.selectedPolicy);
  const selectedPolicyLoading = useAppSelector((state) => state.customerData.policyDetailsLoading);
  const redirect = useIsNoData({ policies, checkPolicies: true });
  const { isMobile, isTablet } = useGetScreenSize();

  if (redirect) {
    return redirect;
  }

  useEffect(() => {
    sendAnalytics({
      event: GTM_EVENT_TYPES.PAGE_VIEW,
      detail: { virtualpage: path, virtualpage_title: GTM_TITLE[path], virtualpage_url: document.location.href }
    });
  }, [Component]);

  const { isSelected, policyNumber } = isPolicySelected(pathname, policies as Policy[]);
  const [show, setShow] = useState(!isSelected);

  const policyDetailsId = policies && policies.find((policy) => policy.policyRef === policyNumber)?.policyDetailsId;
  useSetSelectedPolicy({
    policyNumber,
    policyDetailsId: policyDetailsId as string,
    selectedPolicy
  });

  return (
    <Container className="page-container" fluid={isMobile || isTablet}>
      {selectedPolicyLoading || !selectedPolicy ? (
        <Loading heading="FETCHING YOUR POLICY" text="Please wait while we gather your policy information" />
      ) : (
        <Component setShow={setShow} selectedPolicy={selectedPolicy} />
      )}
      {show && (
        <MultiplePolicyModal policies={policies as Policy[]} setShow={setShow} selectedPolicy={selectedPolicy} />
      )}
    </Container>
  );
};
