export type AuthType = 'passback' | 'auth';

interface AuthTypeDetails {
  authType?: AuthType;
  index: number;
}

const getAuthType = (paths: string[]): AuthTypeDetails => {
  if (paths.includes('auth')) {
    return { authType: 'auth', index: paths.indexOf('auth') };
  }

  if (paths.includes('passback')) {
    return { authType: 'passback', index: paths.indexOf('passback') };
  }

  return { index: -1 };
};

interface AuthLink {
  authToken: string;
  path: string;
  authType?: AuthType;
}

export const processAuthLink = (pathname: string): AuthLink => {
  const paths = pathname.split('/');

  const { index, authType } = getAuthType(paths);
  const token = index > -1 ? paths[index + 1] : '';

  if (index > -1) {
    paths.splice(index, 2);
  }

  const path = paths.filter((item) => item !== '').join('/');

  return { authToken: token, path, authType };
};
