import { useState } from 'react';

import { PolicyField } from 'components/common/policyField';
import { DocumentsGroup } from 'components/content/documents/documentsGroup';
import { Button } from 'components/common/button';
import { useAppDispatch } from 'store/hooks';
import { getPaymentGuidAction } from 'actions/getPaymentGuidAction';
import { Checkbox } from 'components/common/checkbox';
import { PolicyDetail } from 'interfaces/customerDataTypes';

import './paymentInfoPage.scss';

interface PaymentInfoPageProps {
  selectedPolicy: PolicyDetail;
  setClickContinue: (value: boolean) => void;
  acceptedTerms: boolean;
  setAcceptedTerms: (value: boolean) => void;
}

export const PaymentInfoPage = ({
  selectedPolicy,
  setClickContinue,
  acceptedTerms,
  setAcceptedTerms
}: PaymentInfoPageProps): JSX.Element => {
  const [checkboxError, setCheckboxError] = useState(false);
  const dispatch = useAppDispatch();
  const { renewalPaymentAmount } = selectedPolicy;
  const handleButtonClick = () => {
    if (acceptedTerms && renewalPaymentAmount) {
      dispatch(getPaymentGuidAction({ selectedPolicy, amount: renewalPaymentAmount, source: 'Renewal' }));
      setClickContinue(true);
    } else {
      setCheckboxError(true);
    }
  };

  const handleCheckboxClick = () => {
    setCheckboxError(false);
    setAcceptedTerms(!acceptedTerms);
  };

  const renewalDocument = selectedPolicy.documents
    .sort((a, b) => new Date(b.startDate).getTime() - new Date(a.endDate).getTime())
    .find((doc) => doc.documentType === 'RENEWAL_INVITE_DOC' || doc.documentType === 'RENEWED_DOC');

  return (
    <>
      {selectedPolicy.renewalPaymentAmount && (
        <>
          <h2>Make a payment</h2>
          <p className="arrears-message">
            Your account is currently in arrears. Please pay the amount due to remain on cover.
          </p>
          <PolicyField
            label="Total payable"
            value={<p id="renewal-payment-amount">£{selectedPolicy.renewalPaymentAmount}</p>}
          />
          <PolicyField
            label="Address insured"
            value={<p className="policy-field-value">{selectedPolicy.riskAddress}</p>}
          />
          <PolicyField label="Postcode" value={<p className="policy-field-value">{selectedPolicy.riskPostcode}</p>} />
          <PolicyField
            label="Policy reference"
            value={<p className="policy-field-value">{selectedPolicy.policyRef}</p>}
          />
          {renewalDocument && (
            <DocumentsGroup
              name="Renewal documents"
              selectedPolicy={selectedPolicy}
              showAccordion={false}
              policyDocument={renewalDocument}
            />
          )}
          <Checkbox
            labelText="I have read and understood my renewal documents and I am happy with the renewal information provided."
            handleChange={handleCheckboxClick}
            isChecked={acceptedTerms}
            name="acceptedTerms"
            isError={checkboxError}
            errorText="Before you continue, you must agree that you are happy with this statement"
          />
          <Button text="CONTINUE" handleClick={handleButtonClick} />
        </>
      )}
    </>
  );
};
