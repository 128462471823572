import { COVER_TYPE } from 'interfaces/customerDataTypes';
import type { CoverType } from 'interfaces/customerDataTypes';

export const getCoverTypeIcon = (coverType?: CoverType): string => {
  switch (coverType) {
    case COVER_TYPE.BUILDINGS_AND_CONTENTS:
      return 'buildings-and-contents';
    case COVER_TYPE.CONTENTS:
      return 'contents';
    case COVER_TYPE.BUILDINGS:
      return 'buildings';
    default:
      return 'buildings';
  }
};
