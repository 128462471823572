import queryString from 'query-string';

import type { LoginOptions } from 'components/content/login/loginPage/loginPage';

import { getConfig } from '../config/configuration';
import type { HpDispatchFunction } from './actionTypes';
import { fetchResolver } from './fetchResolver';
import { ErrorMap } from 'interfaces/errorTypes';
import { getLoginValidation } from 'validation/loginValidation';

export interface AuthLinkEmailPayload {
  emailAddress: string;
  postcode: string;
  tab: LoginOptions;
}
export function sendAuthLinkAction(payload: AuthLinkEmailPayload) {
  return (dispatch: HpDispatchFunction<ErrorMap | { [key: string]: string }>): Promise<void> | undefined => {
    const validation = getLoginValidation(payload);

    if (Object.keys(validation.errors).length > 0) {
      dispatch({
        type: 'LOGIN_VALIDATION_ERROR',
        payload: validation.errors
      });
    } else {
      dispatch({ type: 'LOGIN_VALIDATION_SUCCESS' });

      const { websiteEndpoint } = getConfig();
      const queryText = queryString.stringify(payload);
      const url = `${websiteEndpoint}/api/send-auth-email?${queryText}`;

      return fetchResolver<any>({
        options: { url, method: 'POST', description: `Send auth link` },
        dispatch,
        successAction: () => {
          dispatch({ type: 'EMAIL_AUTH_LINK_SUCCESS' });
        },
        failedAction: (err) => {
          dispatch({ type: 'EMAIL_AUTH_LINK_ERROR', payload: err.message });
        }
      });
    }
  };
}
