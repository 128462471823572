import { setLoggerForClientAndServerUtilitiesPackage } from '@avantia/client-and-server-utilities';
import { createBrowserConsoleLogger } from '@avantia/logging-and-errors';
import { HpClientDebugFlags, logInfo, setLoggerFlagsForClient, setLoggerForClient } from '../clientLogging';
import { getConfig as getSpecificConfig } from './configuration.Octopus';
import { ClientConfiguration } from './configurationTypes';

let cfg: ClientConfiguration | undefined = undefined;

export function getConfig(): ClientConfiguration {
  if (cfg) {
    return cfg;
  }

  const config = Object.freeze(getSpecificConfig());
  cfg = config;

  const logger = createBrowserConsoleLogger();
  setLoggerForClient(logger);
  setLoggerFlagsForClient(config.clientDebugFlags);
  setLoggerForClientAndServerUtilitiesPackage(logger);

  logInfo(config as any, HpClientDebugFlags.Configuration);

  return config;
}
