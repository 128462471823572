import { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { PaymentPortalPage } from '../../common/paymentPortalPage';
import './paymentManagementWebchat.scss';
import { useAppDispatch } from 'store/hooks';
import { useLocation } from 'react-router';
import { getPaymentGuidFromUrl } from 'actions/index';
import { useGetScreenSize } from 'components/utils';
import { PaymentSuccessPage } from './paymentSuccessPage';

export const PaymentManagementWebchat = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const { isMobile, isTablet } = useGetScreenSize();

  useEffect(() => {
    dispatch(getPaymentGuidFromUrl(pathname));
  }, [pathname]);

  return (
    <Container className="page-container" fluid={isMobile || isTablet}>
      <div className="payment-container">
        <PaymentPortalPage paymentSuccessPage={<PaymentSuccessPage />} />
      </div>
    </Container>
  );
};
