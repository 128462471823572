import { useEffect } from 'react';

import type { HpSvgIconTypes } from '@avantia/style-guide';
import { SvgIcon } from '@avantia/style-guide';

import { GTM_EVENT_TYPES, sendAnalytics } from 'analytics';
import { PATH } from 'interfaces/pathTypes';

import './loginSuccess.scss';

export const LoginSuccess = (): JSX.Element => {
  useEffect(() => {
    sendAnalytics({
      event: GTM_EVENT_TYPES.PAGE_VIEW,
      detail: {
        virtualpage_title: 'Homeprotect • Log in success',
        virtualpage: PATH.LOGIN,
        virtualpage_url: document.location.href
      }
    });
  }, []);

  return (
    <div className="success-container">
      <div className="circle">
        <SvgIcon type={'envelope' as HpSvgIconTypes} className="mail-icon" />
      </div>
      <h2 id="success-header">PLEASE CHECK YOUR EMAIL</h2>
      <p>We&apos;ve sent you a secure link to access your policy information.</p>
      <p>This link can be used once and is valid for 24 hours.</p>
    </div>
  );
};
