import { useEffect } from 'react';
import { Route, Switch, useLocation, useHistory } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getCustomerInsuranceSummaryAction } from 'actions/getCustomerInsuranceSummaryAction';

import { getConfig } from '../config/configuration';
import { PATH } from '../interfaces/pathTypes';
import { ErrorPage } from './common/errorPage';
import { ProtectedRoute } from './common/protectedRoute';
import { Dashboard } from './content/dashboard';
import { Claims } from './content/claims';
import { Quotes } from './content/quotes';
import { Documents } from './content/documents';
import { Login } from './content/login';
import { Settings } from './content/settings';
import { RenewalPayment } from './content/renewalPayment';
import { Footer } from './layout/footer';
import { Header } from './layout/header';
import { ComponentWithModal } from './common/componentWithModal';
import { processAuthLink } from './utils';
import { PaymentManagementWebchat } from './content/paymentManagementWebchat';
import { PaymentManagement } from './content/paymentManagement';

const config = getConfig();
const { websiteEndpoint } = config;

function Routes(): JSX.Element {
  const { pathname, search } = useLocation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector((state) => state.customerData.isAuthenticated);
  const isProcessing = useAppSelector((state) => state.customerData.customerInsuranceSummaryLoading);

  useEffect(() => {
    const { path, authToken, authType } = processAuthLink(pathname);
    path ? history.replace(`/${path}${search}`) : history.replace(`/dashboard${search}`);
    dispatch(getCustomerInsuranceSummaryAction({ authToken, authType }));
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  }, []);

  return (
    <div>
      <Header />
      <main>
        <Switch>
          <Route path={websiteEndpoint + PATH.LOGIN} component={Login} />
          <Route path={websiteEndpoint + PATH.PAYMENT_MANAGEMENT_WEBCHAT} component={PaymentManagementWebchat} />
          <ProtectedRoute
            path={websiteEndpoint + PATH.QUOTES}
            component={Quotes}
            isAuthenticated={isAuthenticated}
            isProcessing={isProcessing}
          />
          <ProtectedRoute
            path={websiteEndpoint + PATH.DOCUMENTS}
            component={ComponentWithModal}
            isAuthenticated={isAuthenticated}
            isProcessing={isProcessing}
            // @ts-expect-error covered by rest
            childComponent={Documents}
          />
          <ProtectedRoute
            path={websiteEndpoint + PATH.SETTINGS}
            component={ComponentWithModal}
            isAuthenticated={isAuthenticated}
            isProcessing={isProcessing}
            // @ts-expect-error covered by rest
            childComponent={Settings}
          />
          <ProtectedRoute
            path={websiteEndpoint + PATH.RENEWAL_PAYMENT}
            component={ComponentWithModal}
            isAuthenticated={isAuthenticated}
            isProcessing={isProcessing}
            // @ts-expect-error covered by rest
            childComponent={RenewalPayment}
          />
          <ProtectedRoute
            path={websiteEndpoint + PATH.CLAIMS}
            component={ComponentWithModal}
            isAuthenticated={isAuthenticated}
            isProcessing={isProcessing}
            // @ts-expect-error covered by rest
            childComponent={Claims}
          />
          <ProtectedRoute
            path={websiteEndpoint + PATH.DASHBOARD}
            component={ComponentWithModal}
            isAuthenticated={isAuthenticated}
            isProcessing={isProcessing}
            // @ts-expect-error covered by rest
            childComponent={Dashboard}
          />
          <ProtectedRoute
            path={websiteEndpoint + PATH.PAYMENT_MANAGEMENT}
            component={ComponentWithModal}
            isAuthenticated={isAuthenticated}
            isProcessing={isProcessing}
            // @ts-expect-error covered by rest
            childComponent={PaymentManagement}
          />
          <ProtectedRoute
            path="/"
            component={ComponentWithModal}
            isAuthenticated={isAuthenticated}
            isProcessing={isProcessing}
          />
          <Route component={ErrorPage} />
        </Switch>
      </main>
      <Footer />
    </div>
  );
}

export default Routes;
