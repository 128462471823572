import type { RouteComponentProps } from 'react-router-dom';
import { Route } from 'react-router-dom';
import { Container } from 'react-bootstrap';

import type { LoginPath } from 'interfaces/pathTypes';
import { LOGIN_PATH } from 'interfaces/pathTypes';
import { useGetScreenSize } from 'components/utils';

import type { LoginPageProps } from './loginPage/loginPage';
import { LoginPage } from './loginPage';

import './login.scss';

const LOGIN_TEXT: Record<LoginPath, LoginPageProps> = {
  [LOGIN_PATH.DEFAULT]: {
    title: 'LOG IN',
    tab: 'documents',
    helperText: (
      <p>
        Enter your email address and the postcode
        <span className="helper-text"> associated with the property you&apos;ve insured with us. </span>
        We&apos;ll email you a secure link to access your account.
      </p>
    )
  },
  [LOGIN_PATH.QUOTES]: {
    title: 'RETRIEVE YOUR HOME INSURANCE QUOTE',
    tab: 'quotes',
    helperText: (
      <p>
        Enter your email address and the postcode
        <span className="helper-text"> associated with the property you&apos;d like to insure. </span>We&apos;ll email
        you a secure link to access your previous home insurance quote.
      </p>
    )
  },
  [LOGIN_PATH.CLAIMS]: {
    title: 'LOG IN TO MAKE A CLAIM',
    tab: 'claims',
    helperText: (
      <p>
        Enter your email address and the postcode for the
        <span className="helper-text"> address you&apos;re looking to make a claim against. </span>
        We&apos;ll email you a secure link for you to check your cover and start making a claim.
      </p>
    )
  }
};

export const Login = ({ match }: RouteComponentProps): JSX.Element => {
  const path = match.path ? match.path : '/login';
  const { isMobile, isTablet } = useGetScreenSize();

  return (
    <Container className="login-container" fluid={isMobile || isTablet}>
      {Object.keys(LOGIN_TEXT).map((loginPath) => (
        <Route key={loginPath} exact path={path + loginPath}>
          <LoginPage
            title={LOGIN_TEXT[loginPath].title}
            helperText={LOGIN_TEXT[loginPath].helperText}
            tab={LOGIN_TEXT[loginPath].tab}
            path={path + loginPath}
          />
        </Route>
      ))}
    </Container>
  );
};
