import TagManager, { DataLayerArgs } from 'react-gtm-module';

import { getConfig } from 'config/configuration';

import { logInfo, HpClientDebugFlags } from './clientLogging';
import { PATH, Path } from 'interfaces/pathTypes';
import { removePolicyFromUrl } from 'components/utils';
import type { DocumentTypeCode } from 'interfaces/customerDataTypes';

const { environment } = getConfig();
const debugMode = ['developer', 'dev'].indexOf(environment) >= 0;
let prevAnalyticJson: string;
let timerId: NodeJS.Timeout;

export const GTM_TITLE: Record<Path, string> = {
  [PATH.DASHBOARD]: 'Homeprotect • My dashboard',
  [PATH.QUOTES]: 'Homeprotect • My quotes',
  [PATH.CLAIMS]: 'Homeprotect • Make a claim',
  [PATH.DOCUMENTS]: 'Homeprotect • Policy documents',
  [PATH.LOGIN]: 'Homeprotect • Log in',
  [PATH.SETTINGS]: 'Homeprotect • Settings',
  [PATH.RENEWAL_PAYMENT]: 'Homeprotect • Make a payment',
  [PATH.PAYMENT_MANAGEMENT_WEBCHAT]: 'Homeprotect • Manage payments',
  [PATH.PAYMENT_MANAGEMENT]: 'Homeprotect • Manage payments'
} as const;

export const GTM_EVENT_TYPES = {
  PAGE_VIEW: 'virtualpageview',
  DOWNLOAD_DOCUMENTS: 'document_download',
  AUTO_RENEW: 'autorenewal_status_change',
  LOG_OFF: 'logout',
  OPEN_QUOTE: 'quote_retrieved',
  LOGGED_IN: 'login',
  QINTERACTION: 'qinteraction'
} as const;

export type GtmEventTypes = (typeof GTM_EVENT_TYPES)[keyof typeof GTM_EVENT_TYPES];

export const Q_INTERACTION_TYPES = {
  PAGE_NAVIGATION_CLICK: 'pagenavigationclick',
  CLICK: 'click',
  PRIMARY_ACTION_BUTTON_CLICK: 'primaryactionbuttonclick',
  SECONDARY_ACTION_BUTTON_CLICK: 'secondaryactionbuttonclick'
} as const;

type QinteractionType = (typeof Q_INTERACTION_TYPES)[keyof typeof Q_INTERACTION_TYPES];

interface Detail {
  virtualpage?: string;
  virtualpage_url?: string;
  virtualpage_title?: string;
  auth_type?: 'auth' | 'passback';
  policy_count?: number;
  quote_count?: number;
  is_auto_renewal?: boolean;
  quote_reference?: string;
  agreement_id?: string;
  document_type?: DocumentTypeCode;
  qinteraction_type?: QinteractionType;
  qinteraction_locator?: string;
  qinteraction_value?: string;
}

export interface DataLayerProps {
  event: GtmEventTypes;
  detail?: Detail;
}

export interface SendAnalyticsProps {
  event: GtmEventTypes;
  checkRepeats?: boolean; // if this is false, the previous page wont be added after this event.
  detail?: Detail;
  delay?: number;
  onlyWhen?: (props: DataLayerProps) => boolean;
}

export const sendAnalytics = ({ event, checkRepeats, detail, delay, onlyWhen }: SendAnalyticsProps) => {
  const sender = () => {
    const dataLayer: DataLayerProps = {
      event
    };

    if (detail) {
      const { virtualpage_url } = detail;
      const urlNoPolicyRef = virtualpage_url ? removePolicyFromUrl(virtualpage_url) : undefined;

      dataLayer.detail = { ...detail, virtualpage_url: urlNoPolicyRef };
    }

    const args: DataLayerArgs = {
      dataLayer
    };

    if (onlyWhen && onlyWhen(dataLayer) !== true) {
      return;
    }

    const forLogging = { type: 'analytics', ...args };
    if (checkRepeats !== false) {
      checkRepeats = true;
    }

    if (!checkRepeats || prevAnalyticJson !== forLogging) {
      if (checkRepeats) {
        prevAnalyticJson = forLogging;
      }

      if (debugMode) {
        logInfo(forLogging, HpClientDebugFlags.Configuration);
      }

      TagManager.dataLayer(args);
    }
  };

  if (timerId) {
    clearTimeout(timerId);
  }

  if (delay) {
    timerId = setTimeout(sender, delay);
  } else {
    sender();
  }
};
