import { HpClientDebugFlags, logInfo } from 'clientLogging';
import type {
  HpMessage,
  HpMessageCommon,
  HpMessageContent,
  HpPaymentOutcomeMessageData
} from 'interfaces/messageTypes';
import { HP_MESSAGE_TYPE } from 'interfaces/messageTypes';
import type { HpDispatchFunction } from './actionTypes';

export function getPaymentStatusAction(message: MessageEvent<any>) {
  return (dispatch: HpDispatchFunction<any>): void => {
    logInfo(message.data, HpClientDebugFlags.ReducerProcessor);
    const { data: messageData = {} as HpMessageCommon } = message as HpMessage<HpMessageContent>;
    const { type } = messageData;
    switch (type) {
      case HP_MESSAGE_TYPE.CLIENT_HEIGHT_CHANGE:
        dispatch({
          type: 'CLIENT_HEIGHT_CHANGE'
        });
        break;
      case HP_MESSAGE_TYPE.PAYMENT_ATTEMPT:
        dispatch({
          type: 'PAYMENT_LOADING'
        });
        break;
      case HP_MESSAGE_TYPE.ADDRESS_FORM_DISPLAYED:
        dispatch({
          type: 'ADDRESS_FORM_DISPLAYED'
        });
        break;
      case HP_MESSAGE_TYPE.BRAINTREE_LOADING:
        dispatch({
          type: 'BRAINTREE_LOADING'
        });
        break;
      case HP_MESSAGE_TYPE.ERROR_PAGE_LOADED:
      case HP_MESSAGE_TYPE.BRAINTREE_LOADED:
        dispatch({
          type: 'PORTAL_LOADED'
        });
        break;
      case HP_MESSAGE_TYPE.PAYMENT_OUTCOME: {
        const { paymentReference, paymentStatus, success } = messageData as HpPaymentOutcomeMessageData;

        if (success) {
          const { cardLastFour, cardType, cardImageUrl, expiryDate } = messageData as HpPaymentOutcomeMessageData;
          dispatch({
            type: 'PAYMENT_SUCCESS',
            payload: { paymentReference, cardLastFour, cardType, cardImageUrl, expiryDate }
          });
        } else {
          dispatch({ type: 'PAYMENT_ERROR', payload: paymentStatus });
        }

        break;
      }

      default:
        dispatch({
          type: 'UNKNOWN_PAYMENT_MESSAGE'
        });
        break;
    }
  };
}
