import './arrowLink.scss';

interface ArrowLinkProps {
  linkText: string;
}

export const ArrowLink = ({ linkText }: ArrowLinkProps): JSX.Element => {
  return (
    <div className="arrow-link">
      <div className="arrow"></div>
      <p className="arrow-text">{linkText}</p>
    </div>
  );
};
