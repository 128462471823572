import { SvgIcon } from '@avantia/style-guide';
import type { HpSvgIconTypes } from '@avantia/style-guide';
import { useContext } from 'react';
import { useAccordionToggle, Accordion, Card, AccordionContext } from 'react-bootstrap';

import './accordionToggle.scss';

interface AccordionToggleProps {
  eventKey: string;
  callback?: (eventKey: string) => void;
  setIsDisabled: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AccordionToggle = ({ eventKey, callback, setIsDisabled }: AccordionToggleProps) => {
  const currentEventKey = useContext(AccordionContext);

  const isCurrentEventKey = currentEventKey === eventKey;

  const decoratedOnClick = useAccordionToggle(eventKey, () => {
    isCurrentEventKey && setIsDisabled(false);
    callback && callback(eventKey);
  });

  return (
    <Accordion.Toggle as={Card.Header} eventKey="0" onClick={decoratedOnClick} id="accordion-toggle">
      <p className="email-text av-text-large">Email my documents</p>
      <SvgIcon
        type={(isCurrentEventKey ? 'angle-up' : 'angle-down') as HpSvgIconTypes}
        size="semi-tiny"
        data-testid="accordion-icon"
      />
    </Accordion.Toggle>
  );
};
