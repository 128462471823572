import { SvgIcon } from '@avantia/style-guide';
import type { HpSvgIconTypes } from '@avantia/style-guide';
import { useAppSelector } from 'store/hooks';

import background from 'assets/images/background-light-blue.png';
import blueBuilding from 'assets/images/light-blue-building.png';
import blueBuildingMobile from 'assets/images/light-blue-building-mobile.png';
import { useGetScreenSize } from 'components/utils';
import type { Path } from 'interfaces/pathTypes';
import { PATH } from 'interfaces/pathTypes';

import './title.scss';

export const TITLE: Record<Path, string> = {
  [PATH.DASHBOARD]: 'WELCOME BACK',
  [PATH.QUOTES]: 'YOUR QUOTES',
  [PATH.CLAIMS]: 'MAKE A HOME INSURANCE CLAIM',
  [PATH.DOCUMENTS]: 'POLICY DOCUMENTS',
  [PATH.LOGIN]: 'WELCOME BACK',
  [PATH.SETTINGS]: 'SETTINGS',
  [PATH.RENEWAL_PAYMENT]: 'MAKE A PAYMENT',
  [PATH.PAYMENT_MANAGEMENT_WEBCHAT]: 'MANAGE PAYMENTS',
  [PATH.PAYMENT_MANAGEMENT]: 'MANAGE PAYMENTS'
};

export const ICON: Record<Path, string> = {
  [PATH.DASHBOARD]: '',
  [PATH.QUOTES]: 'document-circle',
  [PATH.CLAIMS]: 'broken-circle',
  [PATH.DOCUMENTS]: 'document-circle',
  [PATH.LOGIN]: 'user-circle',
  [PATH.SETTINGS]: 'cog-circle',
  [PATH.RENEWAL_PAYMENT]: 'credit-card-circle',
  [PATH.PAYMENT_MANAGEMENT_WEBCHAT]: 'credit-card-circle',
  [PATH.PAYMENT_MANAGEMENT]: 'credit-card-circle'
};

interface TitleProps {
  pathname: Path;
}

export const Title = ({ pathname }: TitleProps): JSX.Element => {
  const path = Object.keys(TITLE).find((title) => `/${pathname.split('/')[1]}` === title) ?? PATH.DASHBOARD;
  const selectedPolicy = useAppSelector((state) => state.customerData.selectedPolicy);

  const { isMobile, isTablet } = useGetScreenSize();

  const getDashboardImage = (): JSX.Element => {
    if (isMobile) {
      return <img src={blueBuildingMobile} height={201} width={211} alt="Building title image"></img>;
    }

    if (isTablet) {
      return <img src={blueBuildingMobile} height={361} width={386} alt="Building title image"></img>;
    }

    return <img src={blueBuilding} height={384} width={682} alt="Building title image"></img>;
  };

  if (path === PATH.DASHBOARD) {
    return (
      <>
        {selectedPolicy && (
          <div className="dashboard-title">
            <div>
              <h1 className="text" id="dashboard-title">
                {TITLE[path].toLocaleUpperCase()}
              </h1>
              <p className="text">{`${selectedPolicy.title} ${selectedPolicy.forename} ${selectedPolicy.surname}`}</p>
            </div>
            {getDashboardImage()}
          </div>
        )}
      </>
    );
  }

  return (
    <div className="title">
      <img src={background} height={isMobile ? 120 : 224} className="background" alt="Blue title background"></img>
      <div>
        <SvgIcon type={ICON[path] as HpSvgIconTypes} className="icon" />
        <h1 className="text" id={`${path.split('/')[1]}-id`}>
          {TITLE[path].toLocaleUpperCase()}
        </h1>
      </div>
    </div>
  );
};
