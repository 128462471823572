import type { Policy } from 'interfaces/customerDataTypes';

interface PolicySelected {
  isSelected: boolean;
  policyNumber: string;
}

export const isPolicySelected = (pathname: string, policies: Policy[]): PolicySelected => {
  const pathElements = pathname.split('/');
  const policyIndexInPath = pathElements.indexOf('policy');
  const policyRef = `${pathElements[policyIndexInPath + 1]}/${pathElements[policyIndexInPath + 2]}`;
  const policyIndexInData = policies.findIndex((item) => item.policyRef === policyRef);

  if (policyIndexInPath > -1 && policyIndexInData > -1) {
    return { isSelected: true, policyNumber: policies[policyIndexInData].policyRef };
  }

  if (policies && policies.length === 1) {
    return { isSelected: true, policyNumber: policies[0].policyRef };
  }

  return { isSelected: false, policyNumber: policies[0].policyRef };
};
