import { CustomerInsuranceSummaryResponse } from 'actions/getCustomerInsuranceSummaryAction';
import { PolicyDetail } from 'interfaces/customerDataTypes';
import { HpAction, HpTemplateActionTypes } from '../actions/actionTypes';
import { CustomerDataState, getInitialState } from './initialState';
import { ReducerProcessor } from './reducerProcessor';

const processor = new ReducerProcessor<CustomerDataState, HpTemplateActionTypes>('customer');

processor.add('CUSTOMER_POLICY_DETAILS_LOADING', (state) => {
  return {
    ...state,
    policyDetailsLoading: true
  };
});

processor.add('CUSTOMER_POLICY_DETAILS_ERROR', (state, action) => {
  return {
    ...state,
    policyDetailsLoading: false,
    policyDetailsError: action.payload,
    isPolicyDetailsError: true,
    selectedPolicy: undefined
  };
});

processor.add('CUSTOMER_POLICY_DETAILS_SUCCESS', (state, action) => {
  return {
    ...state,
    policyDetailsLoading: false,
    isPolicyDetailsError: false,
    selectedPolicy: action.payload
  };
});

processor.add('TOGGLE_AUTO_RENEW_SUCCESS', (state, action) => {
  return {
    ...state,
    selectedPolicy: action.payload,
    toggleAutoRenewLoading: false
  };
});

processor.add('TOGGLE_AUTO_RENEW_ERROR', (state, action) => {
  return {
    ...state,
    toggleAutoRenewLoading: false,
    toggleAutoRenewalError: action.payload
  };
});

processor.add('TOGGLE_AUTO_RENEW_LOADING', (state) => {
  return {
    ...state,
    toggleAutoRenewLoading: true
  };
});

processor.add('CUSTOMER_INSURANCE_SUMMARY_LOADING', (state) => {
  return {
    ...state,
    customerInsuranceSummaryLoading: true
  };
});

processor.add('CUSTOMER_INSURANCE_SUMMARY_ERROR', (state, action) => {
  const isNoTokenError = action.payload === 'There is no auth token in the session.';
  return {
    ...state,
    customerInsuranceSummaryLoading: false,
    authLinkError: action.payload,
    customerInsuranceSummaryError: true,
    isTokenError: isNoTokenError
  };
});

processor.add('CUSTOMER_INSURANCE_SUMMARY_SUCCESS', (state, action) => {
  return {
    ...state,
    customerInsuranceSummaryLoading: false,
    isAuthenticated: true,
    customerInsuranceSummaryError: false,
    policies: action.payload.policies,
    quotes: action.payload.quotes
  };
});

processor.add('SIGN_OUT_LOADING', (state) => {
  return {
    ...state,
    isAuthenticated: false
  };
});

export function customerDataReducer(
  state: CustomerDataState = getInitialState().customerData,
  action: HpAction<CustomerInsuranceSummaryResponse | PolicyDetail | string>
): CustomerDataState {
  return processor.process(state, action);
}
