import { useLocation } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';

import { PATH, EXTERNAL_PATH } from 'interfaces/pathTypes';
import type { Path } from 'interfaces/pathTypes';
import { PAGE_NAV_IMAGE } from 'interfaces/navigationTypes';
import { useGetScreenSize } from 'components/utils';

import { getPolicyFromUrl } from 'components/common/multiplePolicyModal/utils/getPolicyFromUrl';
import type { PageNavigationItemProps } from './pageNavigationItem/pageNavigationItem';
import { PageNavigationItem } from './pageNavigationItem';

import './pageNavigation.scss';

const getPageCards = (policyPath: string): PageNavigationItemProps[] => [
  {
    title: 'MY DOCUMENTS',
    imageUrl: PAGE_NAV_IMAGE.DOCUMENTS,
    linkUrl: `${PATH.DOCUMENTS}/${policyPath}`,
    cardKey: 'documents'
  },
  {
    title: 'MAKE A CLAIM',
    imageUrl: PAGE_NAV_IMAGE.CLAIMS,
    linkUrl: `${PATH.CLAIMS}/${policyPath}`,
    cardKey: 'claims'
  },
  {
    title: 'CONTACT US',
    imageUrl: PAGE_NAV_IMAGE.CONTACT,
    linkUrl: EXTERNAL_PATH.CONTACT_US,
    isExternal: true,
    cardKey: 'contact'
  },
  {
    title: 'MY DASHBOARD',
    imageUrl: PAGE_NAV_IMAGE.DASHBOARD,
    linkUrl: `${PATH.DASHBOARD}/${policyPath}`,
    cardKey: 'dashboard'
  },
  {
    title: 'FREQUENTLY ASKED QUESTIONS',
    imageUrl: PAGE_NAV_IMAGE.FAQ,
    linkUrl: EXTERNAL_PATH.FAQS,
    isExternal: true,
    cardKey: 'faq'
  }
];

const getFilteredPageCards = (page: Path, policyPath: string): PageNavigationItemProps[] | null => {
  switch (page) {
    case PATH.QUOTES:
      return getPageCards(policyPath).filter((item) => item.title !== 'CONTACT US');
    case PATH.DOCUMENTS:
      return getPageCards(policyPath).filter((item) => item.title !== 'MY DOCUMENTS');
    case PATH.CLAIMS:
      return getPageCards(policyPath).filter((item) => item.title !== 'MAKE A CLAIM');
    case PATH.SETTINGS:
      return getPageCards(policyPath).filter((item) => item.title !== 'FREQUENTLY ASKED QUESTIONS');
    case PATH.RENEWAL_PAYMENT:
      return getPageCards(policyPath).filter((item) => item.title !== 'MAKE A CLAIM');
    case PATH.PAYMENT_MANAGEMENT:
      return getPageCards(policyPath).filter((item) => item.title !== 'MY DASHBOARD');
    default:
      return null;
  }
};

interface PageNavigationProps {
  page: Path;
}

export const PageNavigation = ({ page }: PageNavigationProps): JSX.Element => {
  const { pathname } = useLocation();
  const { policyPath } = getPolicyFromUrl(pathname);
  const { isTablet } = useGetScreenSize();

  return (
    <div className="navigation-container">
      <Row>
        <h3 className="resources-text">MORE RESOURCES</h3>
      </Row>
      <Row className={`card-row ${isTablet ? '' : 'd-flex flex-row flex-nowrap'}`}>
        {!!getFilteredPageCards(page, policyPath) &&
          getFilteredPageCards(page, policyPath)?.map((card) => (
            <Col key={card.title} sm={isTablet ? 6 : undefined}>
              <PageNavigationItem
                title={card.title}
                imageUrl={card.imageUrl}
                linkUrl={card.linkUrl}
                isExternal={card.isExternal}
                cardKey={card.cardKey}
              />
            </Col>
          ))}
      </Row>
    </div>
  );
};
