import { SvgIcon } from '@avantia/style-guide';
import type { HpSvgIconTypes } from '@avantia/style-guide';
import { Row, Navbar as BootstrapNavbar, Col } from 'react-bootstrap';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import { useGetScreenSize } from 'components/utils';
import { getConfig } from 'config/configuration';
import { PATH } from 'interfaces/pathTypes';
import { useAppSelector } from 'store/hooks';
import { NavModal } from './navModal';

import './navbar.scss';

const { assetsUrl, logoPath } = getConfig();
const iconUrl = assetsUrl + logoPath;

export const Navbar = (): JSX.Element => {
  const [showNavModal, setShowNavModal] = useState(false);
  const isAuthenticated = useAppSelector((state) => state.customerData.isAuthenticated);
  const { isMobile } = useGetScreenSize();

  const openHandler = () => {
    setShowNavModal(true);
  };

  const closeHandler = () => {
    setShowNavModal(false);
  };

  return (
    <>
      <BootstrapNavbar className="nav">
        <Row>
          {isMobile && (
            <div className="menu-col">
              {isAuthenticated && (
                <div>
                  <a onClick={openHandler}>
                    <SvgIcon type={'bars' as HpSvgIconTypes} size="small" />
                  </a>
                </div>
              )}
            </div>
          )}
          <Col xs={6} sm={6} lg={6} className="logo-col">
            <Link to={PATH.DASHBOARD}>
              <img
                src={iconUrl}
                width={isMobile ? 101.47 : 162.86}
                height={isMobile ? 15 : 24}
                alt="Homeprotect logo"
                data-testid="logo"
              />
            </Link>
          </Col>
          <Col xs={6} sm={6} lg={6} className="dashboard-col">
            <Link to={PATH.DASHBOARD} className="dashboard-link">
              <p className="nav-title">My Dashboard</p>
            </Link>
          </Col>
          {!isMobile && (
            <div className="menu-col">
              {isAuthenticated && (
                <div className="desktop-menu-button">
                  <p>MENU</p>
                  <a onClick={openHandler} id="nav-modal-button">
                    <SvgIcon type={'bars' as HpSvgIconTypes} size="small" />
                  </a>
                </div>
              )}
            </div>
          )}
        </Row>
      </BootstrapNavbar>
      {showNavModal && <NavModal closeHandler={closeHandler} />}
    </>
  );
};
