import { HpAction, HpTemplateActionTypes } from '../actions/actionTypes';
import { PaymentState, getInitialState } from './initialState';
import { ReducerProcessor } from './reducerProcessor';

const processor = new ReducerProcessor<PaymentState, HpTemplateActionTypes>('payments');

processor.add('PAYMENT_GUID_LOADING', (state) => {
  return {
    ...state,
    isPaymentSetupLoading: true
  };
});

processor.add('PORTAL_LOADED', (state) => {
  return {
    ...state,
    isBraintreeLoading: false
  };
});

processor.add('PAYMENT_GUID_SUCCESS', (state, action) => {
  const paymentGuid = action.payload;
  return {
    ...state,
    isPaymentSetupLoading: false,
    paymentGuid
  };
});

processor.add('PAYMENT_GUID_ERROR', (state) => {
  return {
    ...state,
    isPaymentSetupLoading: false,
    isBraintreeLoading: false,
    isPaymentSetupError: true
  };
});

processor.add('PAYMENT_SUCCESS', (state, action) => {
  const { paymentReference, cardLastFour, cardType, cardImageUrl, expiryDate } = action.payload;
  return {
    ...state,
    isPaymentSuccess: true,
    paymentReference,
    cardLastFour,
    cardType,
    cardImageUrl,
    cardExpiry: expiryDate
  };
});

processor.add('PAYMENT_ERROR', (state, action) => {
  const paymentStatus = action.payload;
  return {
    ...state,
    paymentErrorCount: state.paymentErrorCount + 1,
    paymentStatus
  };
});

processor.add('CLEAR_PAYMENT_ERROR', (state) => {
  return {
    ...state,
    paymentErrorCount: 0,
    isPaymentSetupError: false
  };
});

processor.add('CLEAR_PAYMENT_SUCCESS', (state) => {
  return {
    ...state,
    isPaymentSuccess: false,
    isPaymentSetupLoading: false,
    isBraintreeLoading: false
  };
});

export function paymentsReducer(state: PaymentState = getInitialState().payments, action: HpAction<any>): PaymentState {
  return processor.process(state, action);
}
