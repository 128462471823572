import { Container } from 'react-bootstrap';
import { PaymentPortalPage } from '../../common/paymentPortalPage';
import './paymentManagement.scss';
import { useGetScreenSize } from 'components/utils';
import { HpChildComponentProps } from 'components/common/componentWithModal/componentWithModal';
import { PaymentSuccessPage } from './paymentSuccessPage';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useEffect, useState } from 'react';
import { PaymentInformation } from './paymentInformation';
import { clearPaymentSuccessAction, getPaymentGuidAction } from 'actions/index';
import { PageNavigation } from 'components/navigation/pageNavigation';
import { PATH } from 'interfaces/pathTypes';

export const PaymentManagement = ({ selectedPolicy }: HpChildComponentProps): JSX.Element => {
  const { isMobile, isTablet } = useGetScreenSize();
  const [showPaymentPortal, setShowPaymentPortal] = useState(false);
  const dispatch = useAppDispatch();
  const { cardNumber, cardExpiry } = selectedPolicy;
  const cardLastFourDigits = useAppSelector((state) => state.payments.cardLastFour);
  const cardExpiryDate = useAppSelector((state) => state.payments.cardExpiry);

  const handleClickEdit = () => {
    setShowPaymentPortal(false);
    dispatch(clearPaymentSuccessAction());

    dispatch(getPaymentGuidAction({ selectedPolicy, amount: 0.01, source: 'ReplaceCard' }));
    setShowPaymentPortal(true);
  };

  useEffect(() => {
    return () => {
      // Called when component unmounts
      dispatch(clearPaymentSuccessAction());
    };
  }, []);

  return (
    <Container className="page-container" fluid={isMobile || isTablet}>
      <div className="payment-container">
        {showPaymentPortal ? (
          <PaymentPortalPage
            paymentSuccessPage={
              <PaymentSuccessPage
                handleClickEdit={handleClickEdit}
                cardNumber={cardLastFourDigits}
                cardExpiry={cardExpiryDate}
              />
            }
          />
        ) : (
          <PaymentInformation handleClickEdit={handleClickEdit} cardNumber={cardNumber} cardExpiry={cardExpiry} />
        )}
      </div>
      <PageNavigation page={PATH.PAYMENT_MANAGEMENT} />
    </Container>
  );
};
