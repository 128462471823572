import './policyField.scss';

interface PolicyFieldProps {
  label: string;
  value: JSX.Element;
  labelStyles?: string;
}

export const PolicyField = ({ label, value, labelStyles }: PolicyFieldProps): JSX.Element => {
  return (
    <>
      <p className={`label ${labelStyles}`}>{label}</p>
      {value}
    </>
  );
};
