import { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';

import { getFeatureToggleStateAction } from '../actions/getFeatureToggleStateAction';
import Routes from './routes';

function App(): JSX.Element {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(
      getFeatureToggleStateAction({
        featureName: 'ShowFooter'
      })
    );
  }, []);

  document.title = `Homeprotect Customer Portal`;
  return (
    <Router basename={process.env.PUBLIC_URL}>
      <div className="App">
        <Routes />
      </div>
    </Router>
  );
}

export default App;
