import { SvgIcon } from '@avantia/style-guide';
import { addDays } from 'date-fns';
import type { HpSvgIconTypes } from '@avantia/style-guide';
import { Card, Col, Row } from 'react-bootstrap';
import type { SetStateAction, Dispatch } from 'react';

import { formatAddress, formatDate, getPolicyStatusText } from 'components/utils';
import { getCoverTypeIcon } from 'components/utils';

import './multiplePolicyModal.scss';
import { useHistory, useLocation } from 'react-router';
import { addPolicyToPath } from './utils/addPolicyToPath';
import { useAppDispatch } from 'store/hooks';
import { getCustomerPolicyAction } from 'actions/getCustomerPolicyAction';
import { Policy, PolicyDetail } from 'interfaces/customerDataTypes';

interface MultiplePolicyModalProps {
  policies: Policy[];
  setShow: Dispatch<SetStateAction<boolean>>;
  selectedPolicy?: PolicyDetail;
}

export const MultiplePolicyModal = ({ policies, setShow, selectedPolicy }: MultiplePolicyModalProps): JSX.Element => {
  const history = useHistory();
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();

  const handlePolicySelect = (selectedPolicyNumber: string, selectedPolicyDetailsId: string) => {
    if (!selectedPolicy || selectedPolicy.policyRef !== selectedPolicyNumber) {
      history.replace(addPolicyToPath(pathname, selectedPolicyNumber));
      dispatch(getCustomerPolicyAction({ policyDetailsId: selectedPolicyDetailsId }));
    }

    setShow(false);
  };

  return (
    <div className="multiple-policy-container">
      <h1>SELECT A POLICY</h1>
      <Row className="card-container" sm={3} xs={2}>
        {policies.map((policy) => (
          <Col key={policy.policyRef} className="card-column">
            <a
              onClick={() => handlePolicySelect(policy.policyRef, policy.policyDetailsId)}
              id={`${policy.policyRef}-card`}>
              <Card className="policy-card">
                <SvgIcon type={getCoverTypeIcon() as HpSvgIconTypes} size="medium" />
                <h3>HOME INSURANCE</h3>
                <div>
                  <p className="bold-text">Address insured</p>
                  <p>{formatAddress(policy.riskAddress, policy.riskPostcode)}</p>
                  <p className="bold-text">Policy reference</p>
                  <p>{policy.policyRef}</p>
                  <p className="bold-text">Policy status</p>
                  <p>{getPolicyStatusText(policy.policyStatus)}</p>
                  <p className="bold-text">Renewal date</p>
                  <p>{formatDate(addDays(new Date(policy.endDate), 1))}</p>
                </div>
              </Card>
            </a>
          </Col>
        ))}
      </Row>
    </div>
  );
};
