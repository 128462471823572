import { SvgIcon } from '@avantia/style-guide';
import type { HpSvgIconTypes } from '@avantia/style-guide';
import { Card, Col, Row } from 'react-bootstrap';

import { GTM_EVENT_TYPES, sendAnalytics } from 'analytics';
import { formatDate } from 'components/utils';
import { PolicyDocument, PolicyDetail, DocumentTypeCode, DOCUMENT_TYPE_CODE } from 'interfaces/customerDataTypes';
import { DOCUMENT_TYPE } from 'interfaces/documentTypes';
import { ExternalLink } from 'components/common/externalLink';
import { getLinkUrl } from './getLinkUrl';

import './documentCard.scss';

interface DocumentCardProps {
  selectedPolicy: PolicyDetail;
  policyDocument: PolicyDocument;
  documentType: DocumentTypeCode;
}

const getDocTypeLabel = (type: DocumentTypeCode): DocumentTypeCode => {
  switch (type) {
    case DOCUMENT_TYPE_CODE.RENEWED_DOC:
      return DOCUMENT_TYPE.RENEWED;
    case DOCUMENT_TYPE_CODE.RENEWAL_INVITE_DOC:
      return DOCUMENT_TYPE.RENEWAL_INVITE;
    case DOCUMENT_TYPE_CODE.ADJUSTED_DOC:
      return DOCUMENT_TYPE.ADJUSTED_SCHEDULE;
    case DOCUMENT_TYPE_CODE.BOOKLET:
      return DOCUMENT_TYPE.BOOKLET;
    default:
      return DOCUMENT_TYPE.DOC;
  }
};

export const DocumentCard = ({ selectedPolicy, policyDocument, documentType }: DocumentCardProps): JSX.Element => {
  const { emailAddress, policyDetailsId, policyRef } = selectedPolicy;
  const { startDate, documentArchiveId, endDate, policyBookletUrl, documentTemplateId, historyId } = policyDocument;
  const url = getLinkUrl(
    { emailAddress, documentArchiveId, policyBookletUrl, policyDetailsId, documentTemplateId, historyId, policyRef },
    documentType
  );

  const handleClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void = (e) => {
    sendAnalytics({ event: GTM_EVENT_TYPES.DOWNLOAD_DOCUMENTS, detail: { document_type: documentType } });
    e.preventDefault();
  };

  return (
    // @ts-expect-error handleClick has to be here
    <ExternalLink id={`${getDocTypeLabel(documentType)} id`} className="document-link" href={url} onClick={handleClick}>
      <Card className="container document-card">
        <Row>
          <Col xs={2} className="icon-col">
            <SvgIcon type={'pdf' as HpSvgIconTypes} className="file-icon" />
          </Col>
          <Col xs={10} className="text-col">
            <p className="document-title">{getDocTypeLabel(documentType)}</p>
          </Col>
        </Row>
        <Row>
          <Col xs={{ span: 10, offset: 2 }}>
            <p className="date-range">
              {formatDate(startDate)} - {formatDate(endDate)}
            </p>
          </Col>
        </Row>
      </Card>
    </ExternalLink>
  );
};
