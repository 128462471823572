import './accountSettingsItem.scss';

interface AccountSettingsItemProps {
  icon: JSX.Element;
  label: string;
  value: string;
  secondaryValue?: string;
}

export const AccountSettingsItem = ({ icon, label, value, secondaryValue }: AccountSettingsItemProps): JSX.Element => {
  return (
    <div className="settings-item">
      {icon}
      <p className="label-text">{label}</p>
      <p className="first-value">{value}</p>
      <p>{secondaryValue}</p>
    </div>
  );
};
