import { getConfig } from '../config/configuration';
import { FeatureToggleTypes } from '../interfaces/featureToggle';
import { HpAction, HpDispatchFunction } from './actionTypes';
import { fetchResolver } from './fetchResolver';

export type GetFeatureToggleAction = HpAction<GetFeatureToggleActionPayload>;

export interface GetFeatureToggleActionPayload {
  featureName: FeatureToggleTypes;
  isEnabled: boolean;
}

export function getFeatureToggleStateSuccess(payload: GetFeatureToggleActionPayload): GetFeatureToggleAction {
  return { type: 'RETRIEVED_FEATURE_TOGGLE_STATE', payload };
}

export function getFeatureToggleStateAction({
  featureName
}: {
  featureName: string;
}): (dispatch: HpDispatchFunction<GetFeatureToggleActionPayload | undefined>) => Promise<void> {
  const { websiteEndpoint } = getConfig();

  return (dispatch: HpDispatchFunction<GetFeatureToggleActionPayload | undefined>): Promise<void> => {
    const url = `${websiteEndpoint}/api/featureToggle/${encodeURIComponent(featureName)}`;
    return fetchResolver({
      options: { url, method: 'GET', description: `Get feature toggle` },
      dispatch,
      successAction: (payload: GetFeatureToggleActionPayload) => {
        dispatch(getFeatureToggleStateSuccess(payload));
      }
    });
  };
}
