import { getConfig } from '../config/configuration';
import type { HpDispatchFunction } from './actionTypes';
import { fetchResolver } from './fetchResolver';
import { ErrorMap } from 'interfaces/errorTypes';
import { PolicyDetail } from 'interfaces/customerDataTypes';

interface GetPaymentGuidActionProps {
  selectedPolicy: PolicyDetail;
  amount: number;
  source: string;
}

export function getPaymentGuidAction({ selectedPolicy, amount, source }: GetPaymentGuidActionProps) {
  return (dispatch: HpDispatchFunction<ErrorMap | { [key: string]: string }>): Promise<void> | undefined => {
    dispatch({ type: 'PAYMENT_GUID_LOADING' });
    const {
      policyDetailsId,
      historyId,
      title,
      forename,
      surname,
      policyRef,
      insuredPartyCustomerId,
      phoneNumber,
      riskAddress,
      riskPostcode
    } = selectedPolicy;

    const data = {
      policyDetailsId,
      historyId,
      title,
      forename,
      surname,
      policyRef,
      insuredPartyCustomerId,
      amount,
      phoneNumber,
      riskAddress,
      riskPostcode,
      source
    };

    const { websiteEndpoint } = getConfig();
    const url = `${websiteEndpoint}/api/payments/setup-payment`;

    return fetchResolver<any>({
      options: { url, data, method: 'POST', description: `Set up a new payment and return the payment guid` },
      dispatch,
      successAction: (response) => {
        dispatch({ type: 'PAYMENT_GUID_SUCCESS', payload: response });
      },
      failedAction: () => {
        dispatch({ type: 'PAYMENT_GUID_ERROR' });
      }
    });
  };
}
