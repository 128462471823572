export interface PolicyDetail {
  policyRef: string;
  policyStatus: PolicyStatus;
  riskAddress: string;
  riskPostcode: string;
  startDate: string;
  endDate: string;
  stopRenewal: boolean;
  policyDetailsId: string;
  historyId: number;
  emailAddress: string;
  cardNumber: string | null;
  cardExpiry?: string;
  allowPolicyMaintenance: boolean;
  altPhoneNumber: string | null;
  phoneNumber: string | null;
  renewalDisplayWarning: RenewalDisplayWarning | null;
  renewalPaymentAmount?: number | null;
  documents: PolicyDocument[];
  insuredPartyCustomerId?: string;
  title: string;
  forename: string;
  surname: string;
  coverType?: CoverType;
}

export interface Policy {
  endDate: string;
  historyId: number;
  policyDetailsId: string;
  policyRef: string;
  policyStatus: PolicyStatus;
  riskAddress: string;
  riskPostcode: string;
  startDate: string;
}

export interface Quote {
  agreementId: string;
  coverType: CoverType;
  dateSaved: string;
  quoteLink: string;
  quotePrice: number;
  quoteRef: string;
  quoteStatus: QuoteStatus;
  riskPostcode: string;
  startDate: string;
}

export const QUOTE_STATUS = {
  AVAILABLE: 'Quote',
  PURCHASED: 'Unavailable (Purchased)',
  REFERRED: 'Unavailable (Referred)',
  UNAVAILABLE: 'Unavailable'
} as const;

export type QuoteStatus = (typeof QUOTE_STATUS)[keyof typeof QUOTE_STATUS];

export const COVER_TYPE = {
  BUILDINGS_AND_CONTENTS: 'Buildings and Contents',
  CONTENTS: 'Contents',
  BUILDINGS: 'Buildings'
} as const;

export type CoverType = (typeof COVER_TYPE)[keyof typeof COVER_TYPE];

export const POLICY_STATUS = {
  ADJUSTED: 'ADJUSTED',
  ACTIVE: 'ACTIVE',
  RENEWED: 'RENEWED',
  CANCELLED: 'CANCELLED',
  TIME_TO_RENEW: 'TIME_TO_RENEW',
  CONTACT_US: 'CONTACT_US',
  EXPIRING: 'EXPIRING',
  PAYMENT_REQUIRED: 'PAYMENT_REQUIRED'
} as const;

export type PolicyStatus = (typeof POLICY_STATUS)[keyof typeof POLICY_STATUS];

export const RENEWAL_DISPLAY_WARNING = {
  CUSTOMER_CALL_IN: 'msgacustomertocallin',
  CUSTOMER_OPTED_OUT: 'msgbcustomeroptedout',
  RENEWAL_BEING_PROCESSED: 'msgcrenewalbeingprocessed',
  EXPIRED_POLICY: 'msgdexpiredpolicy'
};

export type RenewalDisplayWarning = (typeof RENEWAL_DISPLAY_WARNING)[keyof typeof RENEWAL_DISPLAY_WARNING];

export interface PolicyDocument {
  documentArchiveId: number;
  documentType: DocumentTypeCode;
  startDate: string;
  endDate: string;
  policyBookletUrl: string;
  documentTemplateId: number;
  historyId: number;
}

export const DOCUMENT_TYPE_CODE = {
  PURCHASED_DOC: 'PURCHASED',
  ADJUSTED_DOC: 'ADJUSTED',
  RENEWED_DOC: 'RENEWED',
  RENEWAL_INVITE_DOC: 'RENEWAL_INVITE',
  BOOKLET: 'BOOKLET'
};

export type DocumentTypeCode = (typeof DOCUMENT_TYPE_CODE)[keyof typeof DOCUMENT_TYPE_CODE];
