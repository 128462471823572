import { SvgIcon } from '@avantia/style-guide';
import type { HpSvgIconTypes } from '@avantia/style-guide';
import { Row, Col } from 'react-bootstrap';

import { formatDate, getCoverTypeIcon } from 'components/utils';
import { AccountSettingsItem } from './accountSettingsItem';
import { AccountMessage } from './accountMessage';
import { PolicyDetail } from 'interfaces/customerDataTypes';

import './accountSettings.scss';

interface SettingsItem {
  key: string;
  value?: string | null;
  secondaryValue?: string;
  label: string;
  icon: JSX.Element;
}

interface AccountSettingsProps {
  selectedPolicy: PolicyDetail;
}

export const AccountSettings = ({ selectedPolicy }: AccountSettingsProps): JSX.Element => {
  const { riskAddress, riskPostcode, coverType, policyRef, cardNumber } = selectedPolicy;

  const SETTINGS_ITEMS: SettingsItem[] = [
    {
      key: 'riskAddress',
      value: riskAddress,
      secondaryValue: riskPostcode,
      label: 'Address insured',
      icon: <SvgIcon type={'map-marker' as HpSvgIconTypes} size="very-tiny" />
    },
    {
      key: 'coverType',
      value: coverType,
      label: 'Insurance cover',
      icon: (
        <SvgIcon
          type={getCoverTypeIcon(selectedPolicy.coverType) as HpSvgIconTypes}
          size="very-tiny"
          className="settings-icon"
        />
      )
    },
    {
      key: 'policyRef',
      value: policyRef,
      label: 'Policy reference',
      icon: <SvgIcon type={'document' as HpSvgIconTypes} size="very-tiny" />
    },
    {
      key: 'startDate',
      value: formatDate(selectedPolicy.startDate),
      label: 'Start date',
      icon: <SvgIcon type={'calendar-check' as HpSvgIconTypes} size="very-tiny" />
    },
    {
      key: 'endDate',
      value: formatDate(selectedPolicy.endDate),
      label: 'End date',
      icon: <SvgIcon type={'calendar-cross' as HpSvgIconTypes} size="very-tiny" />
    },
    {
      key: 'cardNumber',
      value: cardNumber,
      label: 'Account details',
      icon: <SvgIcon type={'credit-card' as HpSvgIconTypes} size="very-tiny" />
    }
  ];

  return (
    <div className="account-settings">
      <h2>GENERAL ACCOUNT SETTINGS</h2>
      {selectedPolicy.renewalDisplayWarning && <AccountMessage message={selectedPolicy.renewalDisplayWarning} />}
      <Row>
        {SETTINGS_ITEMS.map(
          (item) =>
            selectedPolicy[item.key] &&
            item.value && (
              <Col key={item.key} xs={6} sm={4} className="details-column">
                <AccountSettingsItem
                  icon={item.icon}
                  label={item.label}
                  value={item.value}
                  secondaryValue={item.secondaryValue}
                />
              </Col>
            )
        )}
      </Row>
    </div>
  );
};
