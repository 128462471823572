import { FormEvent } from 'react';
import './checkbox.scss';
interface CheckboxProps {
  labelText: string;
  handleChange: (event: FormEvent) => void;
  id?: string;
  name?: string;
  isChecked: boolean;
  isError?: boolean;
  errorText?: string;
}

export const Checkbox = ({
  labelText,
  handleChange,
  id,
  name,
  isChecked,
  isError,
  errorText
}: CheckboxProps): JSX.Element => {
  return (
    <div className={`form-check ${isError ? 'checkbox-form-group-error' : 'checkbox-form-group'}`}>
      {isError && <p className="error-text">{errorText}</p>}
      <input
        className={`hidden-input ${isChecked ? 'hp-checked' : ''}`}
        id={id}
        name={name}
        onChange={handleChange}
        type="checkbox"
        checked={isChecked}
      />
      <label className="form-check-label checkbox-label" htmlFor={id} tabIndex={0} id={id} onClick={handleChange}>
        <div>{labelText}</div>
      </label>
    </div>
  );
};
