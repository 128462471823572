import { voidFunction } from '@avantia/client-and-server-utilities';
import { SvgIcon } from '@avantia/style-guide';
import type { HpSvgIconTypes } from '@avantia/style-guide';
import { Col, Row } from 'react-bootstrap';
import classNames from 'classnames';

import { ExternalLink } from 'components/common/externalLink/externalLink';
import { useGetScreenSize } from 'components/utils';
import { getConfig } from 'config/configuration';
import logo from 'assets/images/homeprotect-white.png';
import './footer.scss';

const { releaseVersion, assetsUrl } = getConfig();

interface FooterProps {
  version?: string;
}
export const Footer = ({ version }: FooterProps): JSX.Element => {
  const compositeVersion = releaseVersion + (version ? `/${version}` : '');
  const logoUrl = assetsUrl + 'Trustpilot-white-no-stars.png';
  const { isMobile, isTablet } = useGetScreenSize();

  return (
    <footer className={classNames(isTablet || isMobile ? 'fluid-container' : 'container', 'hp-footer')}>
      <Row className="logo-container">
        <Col lg={3} sm={3} xs={12} className="logo">
          <ExternalLink id="homeprotect-link" href="https://www.homeprotect.co.uk">
            <img src={logo} height={isMobile ? 48 : 64} width={isMobile ? 102.35 : 136.47} alt="Homeprotect logo"></img>
          </ExternalLink>
        </Col>
        <Col lg={9} sm={9} xs={12} className="icon-column">
          <ExternalLink id="twitter-link" href="https://twitter.com/homeprotect_uk">
            <SvgIcon type={'twitter-circle' as HpSvgIconTypes} size="small" style={{ fill: 'yellow' }} />
          </ExternalLink>
          <ExternalLink id="facebook-link" href="https://www.facebook.com/homeprotect">
            <SvgIcon type={'facebook' as HpSvgIconTypes} size="small" className="facebook" />
          </ExternalLink>
          <ExternalLink href="https://www.trustpilot.com/review/homeprotect.co.uk" id="trustpilot-link">
            <img src={logoUrl} alt="Trustpilot logo"></img>
          </ExternalLink>
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="footer-text">
          <ul>
            {[
              { id: 'homeprotect', text: 'Homeprotect', url: '' },
              { id: 'privacy', text: 'Privacy policy', url: 'security-privacy' },
              { id: 'legal', text: 'Legal', url: 'legal-terms-of-use' },
              { id: 'cookie-policy', text: 'Cookie policy', url: 'cookie-policy' }
            ].map(({ id, text, url }) => {
              return (
                <li key={text} className="list-item">
                  <ExternalLink id={`${id}-footer-link`} href={`https://www.homeprotect.co.uk/${url}`}>
                    {text}
                  </ExternalLink>
                </li>
              );
            })}
            <li key="manage-privacy-settings">
              <a
                href="/"
                id="change-cookie-prefs-link"
                onClick={(ev) => {
                  ev.stopPropagation();
                  ev.preventDefault();
                  const handler = (window as any).__tcfapi;
                  if (handler) {
                    handler('displayConsentUi', 2, voidFunction);
                  }
                }}
                tabIndex={0}>
                Manage Privacy Settings
              </a>
            </li>
          </ul>
          <p>
            Homeprotect is a registered trademark and trading style of Avantia Insurance Ltd which is authorised and
            regulated by the Financial Conduct Authority (FS Register Number 304432). Home Insurance is underwritten by
            AXA Insurance and other reputable insurers.
          </p>
          <ul className="list">
            <li>Copyright &copy; {new Date().getFullYear()} Avantia Insurance Ltd</li>
            <li>
              <ExternalLink id="avantia-group-footer-link" href="https://www.avantiagroup.co.uk" />
            </li>
            <li>{compositeVersion}</li>
          </ul>
        </Col>
      </Row>
    </footer>
  );
};
