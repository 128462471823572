import { SvgIcon } from '@avantia/style-guide';
import type { HpSvgIconTypes } from '@avantia/style-guide';
import './banner.scss';
interface BannerProps {
  title: string;
  body: string;
}

export const Banner = ({ title, body }: BannerProps): JSX.Element => {
  return (
    <div className="banner">
      <div className="banner-content">
        <SvgIcon type={'price-promise-icon' as HpSvgIconTypes} size="medium" className="banner-icon" />
        <div>
          <h3 className="banner-text banner-title">{title.toLocaleUpperCase()}</h3>
          <p className="banner-text">{body}</p>
        </div>
      </div>
    </div>
  );
};
