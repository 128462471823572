export const removePolicyFromUrl = (url: string): string => {
  const urlElements = url.split('/');
  const policyIndexInUrl = urlElements.indexOf('policy');

  if (policyIndexInUrl > -1) {
    urlElements.splice(policyIndexInUrl, 3);
  }

  return urlElements.join('/');
};
