import { getConfig } from '../config/configuration';
import type { HpDispatchFunction } from './actionTypes';
import { fetchResolver } from './fetchResolver';
import { ErrorMap } from 'interfaces/errorTypes';
import { GTM_EVENT_TYPES, sendAnalytics } from 'analytics';
import { PolicyDetail } from 'interfaces/customerDataTypes';

interface ToggleAutoRenewalStatusProps {
  selectedPolicy: PolicyDetail;
  isAutoRenewal: boolean;
}

export function toggleAutoRenewalStatusAction({ selectedPolicy, isAutoRenewal }: ToggleAutoRenewalStatusProps) {
  return (dispatch: HpDispatchFunction<ErrorMap | PolicyDetail>): Promise<void> | undefined => {
    dispatch({ type: 'TOGGLE_AUTO_RENEW_LOADING' });
    const {
      policyDetailsId,
      historyId,
      emailAddress,
      title,
      forename,
      surname,
      startDate,
      endDate,
      phoneNumber,
      altPhoneNumber,
      riskPostcode,
      riskAddress,
      policyRef
    } = selectedPolicy;

    const data = {
      policyDetailsId,
      historyId,
      emailAddress,
      title,
      forename,
      surname,
      effectiveDate: startDate,
      endDate,
      phoneNumber,
      altPhoneNumber,
      riskPostCode: riskPostcode,
      riskAddress,
      policyNumber: policyRef,
      isAutoRenewal
    };

    const { websiteEndpoint } = getConfig();
    const url = `${websiteEndpoint}/api/customer/policy/toggle-auto-renewal`;

    return fetchResolver<any>({
      options: { url, data, method: 'POST', description: `Toggle auto-renewal status` },
      dispatch,
      successAction: (response) => {
        sendAnalytics({ event: GTM_EVENT_TYPES.AUTO_RENEW, detail: { is_auto_renewal: isAutoRenewal } });
        dispatch({ type: 'TOGGLE_AUTO_RENEW_SUCCESS', payload: response.policy });
      },
      failedAction: (err) => {
        dispatch({ type: 'TOGGLE_AUTO_RENEW_ERROR', payload: err.message });
      }
    });
  };
}
