export const addPolicyToPath = (pathname: string, policyNumber: string): string => {
  const paths = pathname.split('/');
  const policyIndex = paths.indexOf('policy');

  if (policyIndex > -1) {
    paths.splice(policyIndex, 3);
  }

  const pathsWithoutPolicy = paths.filter((item) => item !== '').join('/');
  return pathsWithoutPolicy ? `/${pathsWithoutPolicy}/policy/${policyNumber}` : `/policy/${policyNumber}`;
};
