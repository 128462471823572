import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { addPolicyToPath } from './addPolicyToPath';
import { useAppDispatch } from 'store/hooks';
import { getCustomerPolicyAction } from 'actions/getCustomerPolicyAction';
import { PolicyDetail } from 'interfaces/customerDataTypes';

interface SetSelectedPolicyProps {
  policyNumber: string;
  policyDetailsId: string;
  selectedPolicy?: PolicyDetail;
}

export const useSetSelectedPolicy = ({
  policyNumber,
  policyDetailsId,
  selectedPolicy
}: SetSelectedPolicyProps): void => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { pathname, search } = useLocation();

  useEffect(() => {
    if (!selectedPolicy || selectedPolicy.policyRef !== policyNumber) {
      dispatch(getCustomerPolicyAction({ policyDetailsId }));
      history.replace(addPolicyToPath(pathname, policyNumber) + search);
    }
  }, [policyNumber, policyDetailsId]);
};
