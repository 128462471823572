import { clearPaymentErrorAction } from 'actions/clearPaymentErrorAction';
import { getPaymentStatusAction } from 'actions/index';
import { Loading } from 'components/common/loading';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { PaymentFrame } from 'components/common/paymentFrame';

interface PaymentPortalPageProps {
  paymentSuccessPage: JSX.Element;
}

export const PaymentPortalPage = ({ paymentSuccessPage }: PaymentPortalPageProps): JSX.Element => {
  const paymentGuid = useAppSelector((state) => state.payments.paymentGuid);
  const isBraintreeLoading = useAppSelector((state) => state.payments.isBraintreeLoading);
  const isPaymentSetupLoading = useAppSelector((state) => state.payments.isPaymentSetupLoading);
  const isPaymentSuccess = useAppSelector((state) => state.payments.isPaymentSuccess);

  const dispatch = useAppDispatch();

  const paymentMessageListener = (message: MessageEvent<any>): void => {
    dispatch(getPaymentStatusAction(message));
  };

  useEffect(() => {
    window.removeEventListener('message', paymentMessageListener); // make sure we don't double up.
    window.addEventListener('message', paymentMessageListener);
    return () => {
      // Called when component unmounts
      dispatch(clearPaymentErrorAction());
    };
  }, []);

  if (isPaymentSuccess) {
    return paymentSuccessPage;
  }

  if (isPaymentSetupLoading) {
    return <Loading heading="Loading payment page" text="Please wait while we load the payment page" />;
  }

  return (
    <div>
      {isBraintreeLoading && (
        <Loading heading="Loading payment page" text="Please wait while we load the payment page" />
      )}
      {/* Render iFrame while loading  */}
      <PaymentFrame
        id={0}
        paymentGuid={paymentGuid}
        isBraintreeLoading={isBraintreeLoading}
        buttonText="Save"
        showPaymentHeader={false}
        subHeadingText="EDIT YOUR CARD"
      />
    </div>
  );
};
