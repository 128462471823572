interface PolicyObject {
  policyRef: string;
  policyPath: string;
}
export const getPolicyFromUrl = (pathname: string): PolicyObject => {
  const paths = pathname.split('/');
  const policyIndex = paths.indexOf('policy');

  if (policyIndex > -1) {
    const policyRef = `${paths[policyIndex + 1]}/${paths[policyIndex + 2]}`;
    const policyPath = `policy/${policyRef}`;
    return { policyRef, policyPath };
  }

  return { policyRef: '', policyPath: '' };
};
