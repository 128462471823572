import queryString from 'query-string';

import { getConfig } from '../config/configuration';
import type { HpDispatchFunction } from './actionTypes';
import { fetchResolver } from './fetchResolver';
import { ErrorMap } from 'interfaces/errorTypes';
import { PolicyDetail } from 'interfaces/customerDataTypes';
import { signOutAction } from './signOutAction';

export interface CustomerPolicyDetailsPayload {
  policyDetailsId: string;
}

export interface CustomerPolicyResponseData {
  policy: PolicyDetail;
  success: boolean;
}

export function getCustomerPolicyAction(payload: CustomerPolicyDetailsPayload) {
  return (dispatch: HpDispatchFunction<ErrorMap | CustomerPolicyResponseData>): Promise<void> | undefined => {
    dispatch({ type: 'CUSTOMER_POLICY_DETAILS_LOADING' });
    const { websiteEndpoint } = getConfig();
    const queryText = queryString.stringify(payload);
    const url = `${websiteEndpoint}/api/customer/policy?${queryText}`;

    return fetchResolver<any>({
      options: { url, method: 'GET', description: `Get customer policy details` },
      dispatch,
      successAction: (response) => {
        dispatch({ type: 'CUSTOMER_POLICY_DETAILS_SUCCESS', payload: response.policy });
      },
      failedAction: (err) => {
        dispatch({ type: 'CUSTOMER_POLICY_DETAILS_ERROR', payload: err.message });
        // @ts-expect-error dispatching an action creator rather than an action
        dispatch(signOutAction());
      }
    });
  };
}
