import { SvgIcon } from '@avantia/style-guide';
import type { HpSvgIconTypes } from '@avantia/style-guide';
import { Link, useLocation } from 'react-router-dom';
import { useState } from 'react';
import { Button, Container } from 'react-bootstrap';

import { signOutAction } from 'actions/signOutAction';
import { EXTERNAL_PATH, PATH } from 'interfaces/pathTypes';
import { MultiplePolicyModal } from 'components/common/multiplePolicyModal';
import { ExternalLink } from 'components/common/externalLink';
import { getPolicyFromUrl } from 'components/common/multiplePolicyModal/utils/getPolicyFromUrl';
import { getSignOutUrl, useGetScreenSize } from 'components/utils';
import { useAppSelector, useAppDispatch } from 'store/hooks';

import './navModal.scss';
import { POLICY_STATUS } from 'interfaces/customerDataTypes';

interface NavModalProps {
  closeHandler: () => void;
}

export const NavModal = ({ closeHandler }: NavModalProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const policies = useAppSelector((state) => state.customerData.policies);
  const quotes = useAppSelector((state) => state.customerData.quotes);
  const selectedPolicy = useAppSelector((state) => state.customerData.selectedPolicy);
  const [showMultiplePolicyModal, setShowMultiplePolicyModal] = useState(false);
  const { pathname } = useLocation();
  const { policyRef, policyPath } = getPolicyFromUrl(pathname);
  const { isTablet, isMobile } = useGetScreenSize();

  const signOut = () => {
    dispatch(signOutAction());
    closeHandler();
  };

  return (
    <div className="nav-modal-container">
      <Container fluid={isTablet || isMobile} className="modal-links">
        <a onClick={closeHandler}>
          <SvgIcon type={'cross' as HpSvgIconTypes} size="small" />
        </a>
        {policies && policies.length > 0 && (
          <>
            <Link to={`${PATH.DASHBOARD}/${policyPath}`} onClick={closeHandler} id="dashboard-link">
              My dashboard
            </Link>
            <Link to={`${PATH.DOCUMENTS}/${policyPath}`} onClick={closeHandler} id="documents-link">
              Policy documents
            </Link>
            <Link to={`${PATH.CLAIMS}/${policyPath}`} onClick={closeHandler} id="claims-link">
              Make a claim
            </Link>
            {selectedPolicy && selectedPolicy.insuredPartyCustomerId && (
              <Link to={`${PATH.PAYMENT_MANAGEMENT}/${policyPath}`} onClick={closeHandler} id="payment-link">
                Manage payments
              </Link>
            )}
            {selectedPolicy && selectedPolicy.policyStatus === POLICY_STATUS.PAYMENT_REQUIRED && (
              <Link to={`${PATH.RENEWAL_PAYMENT}/${policyPath}`} onClick={closeHandler} id="renewal-payment-link">
                <SvgIcon type="bell-circle" size="small" />
                <p>Make a payment</p>
              </Link>
            )}
            <Link to={`${PATH.SETTINGS}/${policyPath}`} onClick={closeHandler} id="settings-link">
              Settings
            </Link>
          </>
        )}
        {quotes && quotes.length > 0 && (
          <Link to={`${PATH.QUOTES}/${policyPath}`} onClick={closeHandler} id="quotes-link">
            Quotes
          </Link>
        )}
        <ExternalLink id="faq-link" href={EXTERNAL_PATH.FAQS}>
          FAQs
        </ExternalLink>
        <ExternalLink id="contact-us-link" href={EXTERNAL_PATH.CONTACT_US}>
          Contact us
        </ExternalLink>
        <Link to={getSignOutUrl(pathname)} onClick={signOut}>
          Sign out
        </Link>

        {policies && policies.length > 1 && policyRef && (
          <div className="policy-container">
            <p id="selected-policy-ref">
              <span className="bold-text">Viewing policy</span> {policyRef}
            </p>
            <Button onClick={() => setShowMultiplePolicyModal(true)} id="open-policy-modal">
              Change policy
            </Button>
          </div>
        )}
        {showMultiplePolicyModal && policies && (
          <MultiplePolicyModal policies={policies} setShow={setShowMultiplePolicyModal} />
        )}
      </Container>
    </div>
  );
};
