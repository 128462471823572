import { callRestApi, HpRequestOptions, voidFunction } from '@avantia/client-and-server-utilities';
import { lodash } from '@avantia/lodash';
import { HpError, messageToJsonObject } from '@avantia/logging-and-errors';
import { HpClientDebugFlags, logWarning, logInfo } from '../clientLogging';
import { applyChanges } from '../reducers/reducerLibrary';
import { HpDispatchFunction, HpTemplateActionTypes } from './actionTypes';

export type FetchResolverProps<PayloadT> = FetchResolverOptionProps<PayloadT, HpRequestOptions | Promise<PayloadT>>;

export type FetchResolverPromiseProps<PayloadT> = FetchResolverOptionProps<PayloadT, Promise<PayloadT>>;

interface FetchResolverOptionProps<PayloadT, OptionsT> {
  options: OptionsT;
  fetchSilently?: boolean;
  dispatch: HpDispatchFunction<PayloadT>;
  action?: HpTemplateActionTypes;
  successAction?: (payload: PayloadT) => void;
  failedAction?: (reason: any) => void;
}

export function fetchResolver<PayloadT>(props: FetchResolverProps<PayloadT>): Promise<void> {
  const fetchOptions = props.options as HpRequestOptions;
  if (fetchOptions.url) {
    return fetchResolverPromise({ ...props, options: fetchResolverBasic<PayloadT>(fetchOptions) });
  }

  return fetchResolverPromise(props as FetchResolverPromiseProps<PayloadT>);
}

export function fetchResolverPromise<PayloadT>({
  options: promise,
  dispatch,
  action,
  successAction,
  failedAction,
  fetchSilently: requestSilently
}: FetchResolverPromiseProps<PayloadT>): Promise<void> {
  requestSilently = requestSilently === true;
  if (!requestSilently) {
    dispatch({ type: 'AJAX_CALL_STARTED' });
  }

  return new Promise<void>((resolve, reject) => {
    const failedFunc = failedAction
      ? (reason: any): void => {
          failedAction(reason);
          resolve();
        }
      : reject;

    promise
      .then((payload) => {
        if (!requestSilently) {
          dispatch({ type: 'AJAX_CALL_COMPLETED' });
        }

        if (successAction && lodash.isFunction(successAction)) {
          successAction(payload);
        } else if (action) {
          dispatch({ type: action, payload });
        } else {
          throw new Error("Either 'action' or 'successAction must be provided.");
        }

        resolve();
      })
      .catch(createErrorHandler('Fetching a remote resource', dispatch, failedFunc));
  });
}

export function fetchResolverBasic<PayloadT>(options: HpRequestOptions): Promise<PayloadT> {
  options = { ...options };
  if (options.data && (!options.headers || !options.headers['Content-Type'])) {
    options.headers = applyChanges(options.headers || {}, { 'Content-Type': 'application/json' });
  }

  options.method = options.method || 'GET';

  const wasLogged = logInfo(options as any, HpClientDebugFlags.RestApi);
  const { method, url } = options as HpRequestOptions;

  if (!wasLogged) {
    logInfo({ method, url }, HpClientDebugFlags.RestApi);
  }

  const result = callRestApi<PayloadT>(options);
  result.catch(voidFunction); // code quality check.
  return result.then(({ data }) => {
    return data;
  });
}

function createErrorHandler<PayloadT>(
  message: string,
  dispatch: HpDispatchFunction<PayloadT>,
  failedAction?: (err: any) => void
): (err: any) => void {
  return (err: any): void => {
    const errorInfo = err.responseData ? err.responseData : err;
    const error = new HpError(errorInfo);
    const detail = messageToJsonObject({ message: error, level: 'ERROR' });

    if (!error.isLogged) {
      logWarning(detail, HpClientDebugFlags.RestApi);
      error.isLogged = true;
    }

    dispatch({
      type: 'FETCH_ERROR_OCCURRED',
      fetchError: "We're having problems connecting to our servers at the moment.",
      description: message,
      reason: detail
    });

    if (failedAction) {
      failedAction(detail);
    }
  };
}
