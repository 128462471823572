import { useMemo, useState } from 'react';

import type { HpChildComponentProps } from 'components/common/componentWithModal/componentWithModal';
import { PageNavigation } from 'components/navigation/pageNavigation';
import { getYearString } from 'components/utils';
import { PATH } from 'interfaces/pathTypes';
import { DOCUMENT_TYPE_CODE, PolicyDetail, PolicyDocument } from 'interfaces/customerDataTypes';
import { DocumentsGroup } from './documentsGroup';

import './documents.scss';

type TabOptions = 'current' | 'past';

interface GroupedDocuments {
  currentDoc: PolicyDocument | null;
  sortedPastDocs: PolicyDocument[] | null;
  renewalDoc: PolicyDocument | null;
}

const getGroupedDocuments = (selectedPolicy: PolicyDetail): GroupedDocuments => {
  const documentsSortedByDate = [...selectedPolicy.documents].sort(
    (a, b) => new Date(b.startDate).getTime() - new Date(a.startDate).getTime()
  );

  const renewalDocIndex = documentsSortedByDate.findIndex(
    (doc) => doc.documentType === DOCUMENT_TYPE_CODE.RENEWAL_INVITE_DOC
  );
  const renewalDoc = renewalDocIndex > -1 && documentsSortedByDate.splice(renewalDocIndex, 1)[0];

  return {
    currentDoc: documentsSortedByDate[0],
    sortedPastDocs: documentsSortedByDate.length > 1 ? documentsSortedByDate.slice(1) : null,
    renewalDoc: renewalDoc || null
  };
};

export const Documents = ({ selectedPolicy }: HpChildComponentProps): JSX.Element => {
  const [tab, setTab] = useState<TabOptions>('current');

  const { currentDoc, sortedPastDocs, renewalDoc } = useMemo(
    () => getGroupedDocuments(selectedPolicy),
    [selectedPolicy]
  );

  const handleClick = (selectedTab: TabOptions) => setTab(selectedTab);

  return (
    <>
      <div className="documents-container">
        <h2>YOUR HOME INSURANCE DOCUMENTS</h2>
        {sortedPastDocs && sortedPastDocs.length > 0 && (
          <div className={tab === 'current' ? 'documents-tab-current' : 'documents-tab-past'}>
            <button onClick={() => handleClick('current')} className="current">
              <p>Current</p>
            </button>
            <button onClick={() => handleClick('past')} className="past">
              <p>Past</p>
            </button>
          </div>
        )}
        {tab === 'current' && (
          <>
            {renewalDoc && (
              <DocumentsGroup name="Renewal" selectedPolicy={selectedPolicy} policyDocument={renewalDoc} />
            )}
            {currentDoc && (
              <DocumentsGroup name="Current" selectedPolicy={selectedPolicy} policyDocument={currentDoc} />
            )}
          </>
        )}
        {tab === 'past' && sortedPastDocs && (
          <>
            {sortedPastDocs.map((pastDoc) => (
              <div key={pastDoc.startDate + pastDoc.endDate}>
                <DocumentsGroup
                  name={String(getYearString(pastDoc.startDate))}
                  selectedPolicy={selectedPolicy}
                  policyDocument={pastDoc}
                />
              </div>
            ))}
          </>
        )}
      </div>
      <PageNavigation page={PATH.DOCUMENTS} />
    </>
  );
};
