import { Link } from 'react-router-dom';

import { ExternalLink } from 'components/common/externalLink';
import type { PageNavImage } from 'interfaces/navigationTypes';

import { NavigationCard } from './navigationCard';

import './pageNavigationItem.scss';

export interface PageNavigationItemProps {
  title: string;
  imageUrl: PageNavImage;
  linkUrl: string;
  isExternal?: boolean;
  cardKey: string;
}

export const PageNavigationItem = ({
  title,
  imageUrl,
  linkUrl,
  isExternal,
  cardKey
}: PageNavigationItemProps): JSX.Element => {
  if (isExternal) {
    return (
      <ExternalLink href={linkUrl} className="card-link" id={`${cardKey}-card-link`}>
        <NavigationCard title={title} imageUrl={imageUrl} />
      </ExternalLink>
    );
  }

  return (
    <Link to={linkUrl} id={`${cardKey}-card-link`} className="card-link">
      <NavigationCard title={title} imageUrl={imageUrl} />
    </Link>
  );
};
