import { HpSvgIconTypes, SvgIcon } from '@avantia/style-guide';
import { Col, Row } from 'react-bootstrap';

import './paymentInformation.scss';

interface PaymentInformationProps {
  cardNumber?: string | null;
  cardExpiry?: string | null;
  handleClickEdit: () => void;
}
export const PaymentInformation = ({
  cardNumber,
  cardExpiry,
  handleClickEdit
}: PaymentInformationProps): JSX.Element => {
  return (
    <>
      <h2 className="payment-header">PAYMENT INFORMATION</h2>
      <a onClick={handleClickEdit} data-testid="edit-button">
        <Row className="payment-information">
          <Col xs={10} sm={11}>
            <div className="card-details">
              <SvgIcon type={'credit-card' as HpSvgIconTypes} size="semi-tiny" className="card-icon" />
              <div className="card-text">{cardNumber && <p>Card ending in {cardNumber.slice(-4)}</p>}</div>
            </div>
            <div className="expiry-text">{cardExpiry && <p>Expires {cardExpiry}</p>}</div>
          </Col>
          <Col xs={2} sm={1} className="edit-column">
            <SvgIcon type={'edit-square' as HpSvgIconTypes} className="card-icon" size="semi-tiny" />
          </Col>
        </Row>
      </a>
    </>
  );
};
