import { SvgIcon } from '@avantia/style-guide';
import type { HpSvgIconTypes } from '@avantia/style-guide';

import './quoteStatusWidget.scss';
import { QUOTE_STATUS } from 'interfaces/customerDataTypes';
import type { QuoteStatus } from 'interfaces/customerDataTypes';

interface QuoteStatusProps {
  quoteStatus: QuoteStatus;
}

interface DisplayInfo {
  text: string;
  cssClass: string;
  iconType: string;
}

const getDisplayInfo = (quoteStatus: QuoteStatus): DisplayInfo => {
  switch (quoteStatus) {
    case QUOTE_STATUS.AVAILABLE:
      return { text: 'Available', cssClass: 'available', iconType: 'check' };
    case QUOTE_STATUS.PURCHASED:
      return { text: 'Purchased', cssClass: 'purchased', iconType: 'check-circle' };
    case QUOTE_STATUS.REFERRED:
      return { text: 'Referred', cssClass: 'unavailable', iconType: 'cross' };
    default:
      return { text: 'Unavailable', cssClass: 'unavailable', iconType: 'cross' };
  }
};

export const QuoteStatusWidget = ({ quoteStatus }: QuoteStatusProps): JSX.Element => {
  const { text, cssClass, iconType } = getDisplayInfo(quoteStatus);

  return (
    <div className={cssClass}>
      <SvgIcon type={iconType as HpSvgIconTypes} size="very-tiny" />
      <p>{text}</p>
    </div>
  );
};
