import { useAppDispatch } from 'store/hooks';

import { GTM_EVENT_TYPES, Q_INTERACTION_TYPES, sendAnalytics } from 'analytics';
import { redirectToClaimsPortalAction } from 'actions/redirectToClaimsPortalAction';
import { PageNavigation } from 'components/navigation/pageNavigation';
import type { HpChildComponentProps } from 'components/common/componentWithModal/componentWithModal';
import { Button } from 'components/common/button';
import { PATH } from 'interfaces/pathTypes';

import './claims.scss';

export const Claims = ({ selectedPolicy }: HpChildComponentProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const handleClick = () => {
    sendAnalytics({
      event: GTM_EVENT_TYPES.QINTERACTION,
      detail: {
        qinteraction_type: Q_INTERACTION_TYPES.PRIMARY_ACTION_BUTTON_CLICK,
        qinteraction_locator: 'dcj_start_cta',
        qinteraction_value: 'submit_claim'
      }
    });
    dispatch(
      redirectToClaimsPortalAction({
        emailAddress: selectedPolicy.emailAddress,
        postcode: selectedPolicy.riskPostcode
      })
    );
  };

  return (
    <>
      <div className="claims-container">
        <h2>BEFORE YOU CLAIM</h2>
        <p>
          Before you submit a claim, it&apos;s important to understand the level of cover you have and how your policy
          might be affected. You can find out more about your policy coverage by continuing below.
        </p>
        <Button handleClick={handleClick} text="SUBMIT A CLAIM" className="submit-button" id="open-portal" />
      </div>
      <PageNavigation page={PATH.CLAIMS} />
    </>
  );
};
