import { SvgIcon } from '@avantia/style-guide';
import type { HpSvgIconTypes } from '@avantia/style-guide';
import { Button } from 'react-bootstrap';

import { ExternalLink } from 'components/common/externalLink';
import { EXTERNAL_PATH } from 'interfaces/pathTypes';

import './contactBlock.scss';

export const ContactBlock = (): JSX.Element => {
  return (
    <div className="block-container">
      <SvgIcon type={'phone-circle' as HpSvgIconTypes} />
      <h1>CONTACT US</h1>
      <p className="av-text-large contact-text">
        Need to speak to one of our insurance experts? Call us or send us a message.
      </p>
      <ExternalLink id="contact-us-link" href={EXTERNAL_PATH.CONTACT_US}>
        <Button id="contact-button">Contact us</Button>
      </ExternalLink>
    </div>
  );
};
